import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart-service';

@Component({
  selector: 'app-checkout-container',
  templateUrl: './checkout-container.component.html',
  styleUrls: ['./checkout-container.component.scss']
})
export class CheckoutContainerComponent implements OnInit {

  public isCartEmpty(): boolean {
    return this.cartService.cartIsEmpty();
  }

  constructor(private cartService: CartService) { }

  ngOnInit() {
  }

}
