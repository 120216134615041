<div class="candidate-image-replacer-panel" [formGroup]="candidateImageForm">
  <h6 mat-dialog-title>Replace image for {{candidate.name}}</h6>

  <mat-form-field style="width: 100%">
    <mat-label>Image Url:</mat-label>
    <input matInput formControlName="imageUrl" placeholder="Enter URL of image to copy" />
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="candidate" tabindex="3" [disabled]="candidateImageForm.invalid || candidateImageForm.pristine" (click)="saveImage()">Save</button>
    <button mat-button color="accent" (click)="cancel()" tabindex="2">Cancel</button>
  </div>

</div>
