<div class="home-container">

  <div class="container-fluid blurb-container">
    <div class="row no-gutters">
      <div class="col-12 no-gutters">
        <div class="big-blurb">
          <img src="/assets/images/site//home/HeaderGraphic.jpg" />
          <div class="blurb">

            <div class="blurb-text">
              Help Bring the Change<br />
              We Need
            </div>

            <div class="sub-title">
              BECAUSE YOU CAN
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-md forward-thinking-container">

    <div class="row no-gutters">
      <div lang="col-12" style="width: 100%">
        <div class="heading">
          <div>Create your Dream</div>
          <div>Team for Change.</div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-12 offset-xl-3 col-xl-6" style="width: 100%; text-align: center;">

        <div class="sub-heading">
          Make your voice heard and create the <br />
          kind of society you want to be part of.
        </div>

      </div>
    </div>

  </div>

  <div class="container-md actions-container">
    <div class="row">
      <div class="blu-action col-12 col-lg-4">
        <img src="/assets/images/site/home/Donate.png" />
        <h5>Contribute</h5>
        <hr />
        <p>
          Create your political Dream Team. Donate to dozens or hundreds of candidates in minutes. Check out once. No spam.
        </p>
        <a class="btn btn-primary" [routerLink]="'/about'">LEARN MORE</a>
      </div>

      <div class="blu-action col-12 col-lg-4">
        <img src="/assets/images//site/home/Involved.png" />
        <h5>Get Involved</h5>
        <hr />
        <p>
          Learn about all races, 
          especially the closest ones, and engage with each campaign.
        </p>
        <a class="btn btn-primary" [routerLink]="'/about'">LEARN MORE</a>
      </div>

      <div class="blu-action col-12 col-lg-4">
        <img src="/assets/images//site/home/Organize.png" />
        <h5>Organize</h5>
        <hr />
        <p>
          Talk to your family and friends about the Blunami app, and how they can create immediate change.  
        </p>
        <a class="btn btn-primary" [routerLink]="'/about'">LEARN MORE</a>
      </div>

    </div>
  </div>

  <div class="container press-container">
    <div class="row">
      <div class="col-12">
        <div class="picture-frame">
          <img class="picture" src="/assets/images/site/home/Video.jpg" />
          <div class="blurb-container">
            <div class="blurb">
              <div class="play-arrow-container">
                <a href="https://www.futurized.co/e/the-emergence-of-remote-activism/" target="_blank">
                  <mat-icon>play_arrow</mat-icon>
                </a>
                
              </div>
              <div class="blurb-text">
                <h5>Get to Know Us</h5>
                <p>
                  <a href="https://www.futurized.co/e/the-emergence-of-remote-activism/" target="_blank">CEO Laurent Liscia on Futurized</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid weekly-phrase-container">
    <div class="row">
      <div class="col-12">
        <!--<h3>Maximize your Donation Dollar</h3>-->

        <div class="quote-body">

          <p>
            Due to FEC constraints, the Blunami team regrets not being able to make
            its political shopping cart available to you. You can still make an
            immediate difference by downloading our recommendations documents.
          </p>
          <p>
            They list all contested elections in the House and Senate, and
            Presidential, and provide you with donation or volunteering links
          </p>
          <div class="pdf-links">
            <div>
              <a href="/senate.pdf" target="_blank">Senate</a>
            </div>
            <div>
              <a href="/house.pdf" target="_blank">House</a>
            </div>
            <div>
              <a href="/president.pdf" target="_blank">President</a>
            </div>

          </div>

          <!--<mat-icon style="transform: scaleX(-1)">format_quote</mat-icon>
          <p style="font-style: italic;">The Best bang for your Donation Buck.<br /></p>
          <mat-icon class="mat-icon-rtl-mirror">format_quote</mat-icon>

          <p class="info">
            The Blunami app zereos in on candidates with a strong chance of winning, in any race in the country.  Search tools help you crete your Dream Team in moments, and donate as little as one dollar.
            <br />
            You can make change immediately and accross the nation from the comfort of your home.
          </p>
          <div class="learn-more">
            <a class="btn btn-primary">Learn More</a>
          </div>-->

        </div>
      </div>
    </div>
  </div>

  <div class="container footer-container">
    <div class="row">

      <div class="col-12 col-md-6 col-lg-3">
        <img src="/assets/images/site/Logo.jpg" />
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <div class="links">
          <h6>Links</h6>
          <!--<div>
            <a [routerLink]="'/elections'">Elections</a>
          </div>-->
          <div>
            <a href="https://vote.org" target="_blank">Vote</a>
          </div>
          <div>
            <a [routerLink]="'/about'">About Blunami</a>
          </div>
          <div>
            <a [routerLink]="'/login'">Log In</a>
          </div>
          <div>
            <a [routerLink]="'/legal'">Legal</a>
          </div>
        </div>

      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <h6>Contact</h6>

        <div class="links">
          <a href="mailto: info@blunami.org">info@blunami.org</a>
        </div>

      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <h6>Stay in Touch</h6>

        <div class="social-links">
          <a href="https://facebook.com" target="_blank">
            <img src="/assets/images/site/home/Facebook.png" />
          </a>

          <a href="https://youtube.com" target="_blank">
            <img src="/assets/images/site/home/Youtube.png" />
          </a>

          <a href="https://twitter.com" target="_blank">
            <img src="/assets/images/site/home/TwitterLarge.png" />
          </a>

          <a href="https://TikTok.com" target="_blank">
            <img src="/assets/images/site/home/TikTok.png" />
          </a>

          <a href="https://linkedin.com" target="_blank">
            <img src="/assets/images/site/home/LinkedIn.png" />
          </a>


        </div>

      </div>

    </div>
  </div>

</div>
