import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { NewUserRequestViewModel } from '../../types/new-user-request.view-model';
import { Subscription } from 'rxjs';


@Component({
  selector: 'acc-new-user-registration',
  templateUrl: './new-user-registration.component.html',
  styleUrls: ['./new-user-registration.component.scss']
})
export class NewUserRegistrationComponent implements OnInit, AfterViewInit {
  public isAccountCreated: boolean;
  public newAccountRegistrationForm: FormGroup;

  @ViewChild('firstNameField', { static: true })
  public firstNameField: ElementRef;

  public get emailUsed(): string {
    return this.newAccountRegistrationForm.controls.email.value;
  }

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.newAccountRegistrationForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.max(255)]),
      lastName: new FormControl('', [Validators.required, Validators.max(255)]),
      email: new FormControl('', [Validators.required, Validators.max(255), Validators.email]),
      password: new FormControl('', [Validators.required, Validators.max(255)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.max(255)])
    });
  }

  ngAfterViewInit() {
    this.focus();
  }

  createAccount() {

    const vm: NewUserRequestViewModel = new NewUserRequestViewModel();
    Object.assign(vm, this.newAccountRegistrationForm.value);

    if (vm.password !== vm.confirmPassword) {
      this.newAccountRegistrationForm.setErrors({
        'error': 'Passwords do not match.'
      });
      return;
    }

    const subscription: Subscription = this.accountService.createAccount(vm)
      .subscribe(result => {
        if (result.wasSuccessful) {
          // let them know that we've sent them an email, and they should follow the link
          this.isAccountCreated = true;
        }
        else {
          this.isAccountCreated = false;
          this.newAccountRegistrationForm.setErrors({
            'error': result.reasonValidataionUnsuccessful
          });
        }


        subscription.unsubscribe();
      },
        err => {
          this.newAccountRegistrationForm.setErrors({
            'error': err.error.title
          });
        }
      );
  }

  focus() {
    setTimeout(() => {
      if (this.firstNameField) {
        this.firstNameField.nativeElement.focus();
      }    
    });
  }
}
