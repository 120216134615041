<div class="campaign-edit-container">
  <div class="recurrance-container">

    <div class="label">
      Give this Campaign a name:
    </div>

    <input matInput />

    <div class="label">How often do you want to donate to this Campaign?</div>

    <mat-radio-group aria-label="Select recurrance" [(ngModel)]="selectedRecurrence">
      <mat-radio-button value="daily">Daily</mat-radio-button>
      <mat-radio-button value="weekly">Weekly</mat-radio-button>
      <mat-radio-button value="biweekly">Every 2 weeks</mat-radio-button>
      <mat-radio-button value="monthly">Monthly</mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-checkbox>Send a reminder email 8 hours before processing.</mat-checkbox>

</div>
