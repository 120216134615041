import { Component, OnInit, Input } from '@angular/core';
import { PaymentMethodType, NewPaymentMethod } from '../../../../../types/paymentMethod';
import { PaymentMethodService } from '../../../../../services/payment-method.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-payment-method',
  templateUrl: './edit-payment-method.component.html',
  styleUrls: ['./edit-payment-method.component.scss']
})
export class EditPaymentMethodComponent implements OnInit {
  // todo: we should really rename this to EnterPaymentMethodComponent, since you no longer "edit" an existing PM with it.
  public paymentMethodTypes: PaymentMethodType[];
  public newPaymentMethodForm: FormGroup;

  public months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public years: number[] = [2020, 2021, 2022, 2023, 2024, 2025, 2026];

  public get isValid(): boolean {
    const isValid = this.newPaymentMethodForm.valid;
    return isValid;
  }

  constructor(
    private paymentMethodService: PaymentMethodService
  ) { }

  ngOnInit() {
    this.newPaymentMethodForm = new FormGroup({
      paymentMethodType: new FormControl(null, Validators.required),
      accountNumber: new FormControl('', [Validators.required, Validators.minLength(15), Validators.maxLength(20)]),
      expirationMonth: new FormControl('', Validators.required),
      expirationYear: new FormControl('', Validators.required),
      cvvNumber: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      savePaymentMethod: new FormControl(true)
    });

    this.paymentMethodService.getPaymentMethodTypes().subscribe(results => {
      this.paymentMethodTypes = results;
    });
  }

  public getPaymentMethod(): any {
    const formValue = this.newPaymentMethodForm.value;

    if (!formValue.paymentMethodType) {
      return null;
    }

    const pm = new NewPaymentMethod(formValue);
    const last4 = pm.accountNumber.length >= 4 ? pm.accountNumber.substr(pm.accountNumber.length - 4) : pm.accountNumber;
    pm.name = `${pm.paymentMethodType.name} ending in ${last4}`;
    return pm;
  }
}
