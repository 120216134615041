<mat-card *ngIf="selectedCandidate">
  <mat-card-header>
    <div class="blu-info-header">
      {{selectedCandidate?.bestName}}

      <div class="donate-button">
        <button mat-stroked-button color="primary" (click)="donate()">$</button>
      </div>

    </div>

  </mat-card-header>

  <mat-card-content class="blu-custom-scrollbar">

    <div class="header-content">
      <div class="candidate-icon">
        <img *ngIf="selectedCandidate?.headshot" src="images/candidates/thumbs/{{selectedCandidate?.headshot}}" />
        <mat-icon *ngIf="!selectedCandidate?.headshot" color="primary">person_outline</mat-icon>
      </div>


      <div class="candidate-name">
        {{selectedCandidate?.bestName}}
        <button mat-icon-button (click)="showMoreInfo()" >
          <mat-icon color="primary">info</mat-icon>
        </button>

      </div>
      <div class="candidate-party">{{selectedCandidate?.party.name}}
        <span *ngIf="selectedCandidate?.incumbentChallengerStatus == 'I'" style="font-style: italic;"> (Incumbent)</span>
      </div>
      <div class="candidate-office">{{selectedCandidate?.race.raceType}}</div>
      <div class="race-info">
        <span>{{selectedCandidate?.race.stateName}}</span>
        <span *ngIf="selectedCandidate.race.typeCode === 'H'">, District {{selectedCandidate?.race.district}}</span>
      </div>
    </div>

    <div class="body-content">
      <h3>Funding</h3>

      <div class="funding-info">
        <div class="label">Cash on Hand:</div>
        <div class="value">{{selectedCandidate?.endingCash | currency : 'USD' : 'symbol' : '1.0-0'}}</div>

        <div class="label">Total Raised:</div>
        <div class="value">{{selectedCandidate?.totalReceipts | currency : 'USD' : 'symbol' : '1.0-0'}}</div>

        <div class="label">Total Spent:</div>
        <div class="value">{{selectedCandidate?.totalDispursements | currency : 'USD' : 'symbol' : '1.0-0'}}</div>

      </div>

      <h3>Polling</h3>

      <div class="polling-info">
        <div class="label">FiveThirtyEight:</div>
        <div class="value">+ 10%</div>

        <div class="label">Huff Post:</div>
        <div class="value">+ 7%</div>

        <div class="label">Real Clear Politics:</div>
        <div class="value">-19%</div>

      </div>

    </div>

       

  </mat-card-content>

</mat-card>
