import { Race } from './race';
import { Candidate } from './candidate';
import { Deserializable } from './deserializable.model';
import { RaceType } from './race-type';
import { Party, ActiveParty } from './party';
import { Favorite } from './favorite';

export class ElectionsViewModel implements Deserializable {

  // #region Race view

  public races: Race[];
  private _filteredRaces: Race[];

  public get filteredRaces() {
    if (!this._filteredRaces) {
      this.populateFilteredRaces();
    }
    return this._filteredRaces;
  }

  public selectedRaces: Race[];
  public activeSelectedRace: Race;

  // #endregion

  // #region Candidate view

  public candidates: Candidate[];
  public filteredCandidates: Candidate[];
  public selectedCandidates: Candidate[];
  public activeSelectedCandidate: Candidate;

  // #endregion

  public activeParties: ActiveParty[];

  public raceTypes: RaceType[] = RaceType.getRaceTypes();

  public electionStates: ElectionState[];

  constructor() {
    this.selectedRaces = [];
    this.selectedCandidates = [];
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  public populateFilteredRaces() {
    if (this.filteredCandidates.length === this.candidates.length) {
      this._filteredRaces = this.races;
    }
    else {
      const distinctRaces = {};
      for (let candidate of this.filteredCandidates) {
        if (!distinctRaces[candidate.race.id]) {
          distinctRaces[candidate.race.id] = candidate.race;
        }
      }
      this._filteredRaces = Object.values(distinctRaces);
    }
  }

  public selectCandidate(candidate: Candidate): void {
    this.selectedCandidates= [candidate];
    this.activeSelectedCandidate= candidate;
  }

  public addRaceToSelection(race: Race): void {
    this.selectedRaces.push(race);
    this.activeSelectedRace = race;
  }

  public pickFavorites(favorites: Favorite[]) {
    for (let fav of favorites) {
      const candidate: Candidate = this.candidates.find(c => c.id === fav.candidateId);
      candidate.isFavorite = true;
    }
  }

  public filter(fvm: any): void {
    let result = this.candidates;

    if (fvm.selectedRaceType && fvm.selectedRaceType.code) {
      result = result.filter(c => c.race.typeCode === fvm.selectedRaceType.code);
    }

    if (fvm.selectedParty && fvm.selectedParty.party && fvm.selectedParty.party.code) {
      result = result.filter(c => c.party.code === fvm.selectedParty.party.code);
    }

    if (fvm.selectedRunningStatus) {
      if (fvm.selectedRunningStatus.value !== null) {
        result = result.filter(c => c.isRunning === fvm.selectedRunningStatus.value);
      }      
    }

    if (fvm.selectedHasPictureValue) {
      if (fvm.selectedHasPictureValue.value !== null) {
        if (fvm.selectedHasPictureValue.value == true) {
          result = result.filter(c => c.headshot != null);
        }
        else {
          result = result.filter(c => c.headshot == null);
        }
      }
    }

    if (fvm.selectedState && fvm.selectedState.stateAbbreviation) {
      result = result.filter(c => c.race.stateName === fvm.selectedState.stateName);
    }

    if (fvm.selectedCongressionalDistrict && fvm.selectedCongressionalDistrict !== '(All Districts)') {
      result = result.filter(c => c.race.district === fvm.selectedCongressionalDistrict);
    }

    if (fvm.searchText) {
      result = result.filter(c => c.name.toLowerCase().includes(fvm.searchText.toLowerCase())
        || c.race.stateName.toLowerCase().includes(fvm.searchText.toLowerCase()));
    }

    if (fvm.filterByFavorites) {
      result = result.filter(c => c.isFavorite);
    }

    this.filteredCandidates = result;
    this.populateFilteredRaces();
  }

}

export class ElectionState {
  public stateName: string;
  public stateAbbreviation: string;
  private _districtsDictionary: Record<string, boolean> = {};

  private _districts: string[];
  public get districts(): string[] {
    if (!this._districts) {
      const districtKeys = Object.keys(this._districtsDictionary);

      if (districtKeys.length > 0) {
        const allDistrictsDistrict = '(All Districts)';
        districtKeys.push(allDistrictsDistrict);
      }
      else {
        this._districts = [];
      }

      this._districts = districtKeys.sort();
    }
    return this._districts;
  }

  public addDistrict(districtCode: string): void {
    if (districtCode.trim() != '') {
      this._districtsDictionary[districtCode.trim()] = true;
    }
    
  }
}
