<mat-card [ngClass]="{'selected': isCandidateSelected(candidate), 'donating': isCandidateInCart(candidate) }" (click)="selectCandidate(candidate)" (dblclick)="candidate_dblclick(candidate)">
  <div class="blu-card-header">
    <div class="info">

      <div class="candidate-name-container">

        <div class="candidate-name">
          {{candidate?.bestName}}
        </div>

        <app-favorite-display [candidate]="candidate" [isLoggedIn]="isLoggedIn"></app-favorite-display>

      </div>

      <div class="race-type">
        {{candidate.race.raceType}}
      </div>

    </div>
    <div class="party-icon"
         [matTooltip]="candidate.party.name" matTooltipShowDelay="500" matTooltipHideDelay="300" matTooltipPosition="below"
         [ngClass]="{'democrat': candidate.party.code === 'DEM', 'republican': candidate.party.code === 'REP'}">{{candidate?.party.code}}
    </div>
  </div>

  <div class="race-info-container">
    <div class="race-info">
      <span>{{candidate.race.stateName}}</span>
      <span *ngIf="candidate.race.typeCode === 'H'">, District {{candidate.race.district}}</span>
    </div>
    <div class="star-rating" matTooltip="Excellent donation candidate. See Details panel for more information."
         matTooltipPosition="above" matTooltipShowDelay="1000" matTooltipHideDelay="500">
      <mat-icon color="primary">star</mat-icon>
      <mat-icon color="primary">star</mat-icon>
      <mat-icon color="primary">star</mat-icon>
      <mat-icon color="primary">star</mat-icon>
    </div>
  </div>

  <div class="donation-buttons">
    <button mat-stroked-button color="primary" (click)="donate(1, $event)">$1</button>
    <button mat-stroked-button color="primary" (click)="donate(5, $event)">$5</button>
    <button mat-stroked-button color="primary" (click)="donate(10, $event)">$10</button>
    <button mat-stroked-button color="primary" (click)="donateUnspecified($event)">$</button>
  </div>
</mat-card>


