import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Preferences } from '../types/preferences';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor(private http: HttpClient) { }
  private _preferences: Preferences;

  public getPreferences(): Observable<Preferences> {
    if (this._preferences) {
      return of(this._preferences);
    }
    else {
      const url = 'api/profile/GetPreferences';
      return this.http.get<Preferences>(url)
        .pipe(
          tap(preferences => {
            this._preferences = preferences;
          })
        );
    }
  }

  public savePreferences(prefs: Preferences): Observable<Preferences> {
    const url = 'api/profile/SavePreferences';

    return this.http.post<Preferences>(url, prefs).pipe(
      tap(p => {
        this._preferences = p;
      })
    );
  }
}
