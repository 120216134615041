export class PaymentMethod {
  public id: number;
  paymentMethodTypeId: number;
  paymentMethodType: PaymentMethodType;
  public name: string;
  public createDate: Date;
}

export class PaymentMethodType {
  public id: number;
  public name: string;
}

export class NewPaymentMethod extends PaymentMethod {
  public accountNumber: string;
  public expirationMonth: string;
  public expirationYear: number;
  public cvvCode: string;
  public saveThisPaymentMethod: boolean;

  constructor(paymentMethodFormValue: any) {
    super();
    this.paymentMethodTypeId = paymentMethodFormValue.paymentMethodType.id;
    this.paymentMethodType = paymentMethodFormValue.paymentMethodType;
    this.accountNumber = paymentMethodFormValue.accountNumber.trim().replace(/-/g, '').replace(/\./g, '').replace(/\s/g, '');
    this.expirationMonth = paymentMethodFormValue.expirationMonth;
    this.expirationYear = paymentMethodFormValue.expirationYear;
    this.cvvCode = paymentMethodFormValue.cvvNumber;
    this.saveThisPaymentMethod = paymentMethodFormValue.savePaymentMethod;
  }
}
