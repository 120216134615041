<div class="checkout-container" [hidden]="paymentDeclined">

  <mat-horizontal-stepper [linear]="true" #stepper>

    <mat-step [stepControl]="voterInfoForm">

      <ng-template matStepLabel>
        <!--<mat-icon>subject</mat-icon>-->
        Voter Info
      </ng-template>

      <mat-card class="voter-info-panel-card">

        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>subject</mat-icon>
          </div>
          <mat-card-title>Voter Information</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <acc-voter-info-panel #voterInfoPanel></acc-voter-info-panel>
        </mat-card-content>

      </mat-card>

      <div class="wizard-buttons" style="" align="center">
        <button mat-button color="accent" (click)="cancel()">Cancel</button>
        <button color="primary" mat-raised-button matStepperNext [disabled]="voterInfoForm?.invalid">Review Donation</button>
      </div>

    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <!--<mat-icon>subject</mat-icon>-->
        Review Donation
      </ng-template>

      <mat-card class="donation-summary-card">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>subject</mat-icon>
          </div>
          <mat-card-title>
            <div class="total-donation">
              <div class="label">
                Donation
              </div>
              <div>
                {{totalDonation | currency}}
              </div>
            </div>

          </mat-card-title>
        </mat-card-header>

        <mat-card-content class="blu-custom-scrollbar">
          <div class="donation" *ngFor="let donationLine of donationLines">
            <div class="candidate-name-and-party">
              <span class="candidate-name">{{donationLine.candidate.bestName}}</span>
              <span class="party-icon"
                    [matTooltip]="donationLine.candidate.party.name" matTooltipShowDelay="500" matTooltipHideDelay="300" matTooltipPosition="below"
                    [ngClass]="{'democrat': donationLine.candidate.party.code === 'DEM', 'republican': donationLine.candidate.party.code === 'REP'}">
                {{donationLine.candidate.party.code}}
              </span>
            </div>

            <div class="donation-amount">
              {{donationLine.amount| currency}}
            </div>

          </div>

        </mat-card-content>

        <mat-card-footer>
          <div class="footer-panel">
            <div class="footer-title">
              Total Donation:
            </div>
            <div class="donation-total">
              {{totalDonation | currency}}
            </div>
          </div>


        </mat-card-footer>

      </mat-card>

      <div class="wizard-buttons" align="center">
        <button mat-raised-button color="primary" matStepperPrevious>Back</button>
        <button color="primary" mat-raised-button matStepperNext>Payment Method</button>
      </div>

    </mat-step>

    <mat-step [completed]="selectPaymentMethodComponent.isValid">
      <ng-template matStepLabel>
        <!--<mat-icon>subject</mat-icon>-->
        Payment Method
      </ng-template>

      <mat-card class="payment-method-card">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>subject</mat-icon>
          </div>
          <mat-card-title>
            Payment Method
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <app-select-payment-method #selectPaymentMethodComponent></app-select-payment-method>
        </mat-card-content>

      </mat-card>

      <div class="wizard-buttons" align="center">
        <button mat-raised-button color="primary" matStepperPrevious>Back</button>
        <button color="primary" mat-raised-button matStepperNext [disabled]="!selectPaymentMethodComponent.isValid">Complete Donation</button>
      </div>

    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        Complete Donation
      </ng-template>

      <mat-card class="finalize-donation-card">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>subject</mat-icon>
          </div>
          <mat-card-title>
            Complete Donation
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="mat-body-strong">To complete this donation click the 'Donate Now!' button below.</div>

          <div class="label">Donation</div>

          <div class="donation-summary">{{cartSummary}}</div>

          <div class="label">Payment Method</div>

          <div class="donation-summary">{{paymentMethodSummary}}</div>

          <div *ngIf="false">
            <div class="label">Create Campaign?</div>

            <div class="donation-summary">
              <div class="campaign-info">
                <!--A Campaign is a recurring donation.  You specify how often the donation happens, and you can edit or cancel the campaign at any time.-->
              </div>

              <mat-checkbox [(ngModel)]="makeDonationACampaign" style="font-size: 11pt;">Upgrade this donation into a campaign</mat-checkbox>

              <app-campaign-edit *ngIf="makeDonationACampaign"></app-campaign-edit>

            </div>

          </div>

        </mat-card-content>

      </mat-card>

      <div class="wizard-buttons" align="center">
        <button mat-raised-button color="primary" matStepperPrevious>Back</button>

        <button color="primary" mat-raised-button (click)="donate()" [disabled]="savingInProgress">
          <mat-spinner *ngIf="savingInProgress" color="accent" diameter="20" strokeWidth="4" style="height: 200px; width: 24px;"></mat-spinner>
          Donate Now!
        </button>
      </div>

    </mat-step>

  </mat-horizontal-stepper>

</div>

<div class="failure-panel" [hidden]="!paymentDeclined">
  <mat-card class="payment-declined-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>error</mat-icon>
      </div>
      <mat-card-title>
        Problem with your Donation
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="mat-body-strong">There was a problem processing your donation.</div>

      <div class="declined-message">
        Your payment was declined by our payment processor.  Usually this is due a mistyped credit card information or a failed address verification.
      </div>

      <div class="address-match-info">
        <strong>Please note</strong> that for security reasons, we are unable to obtain payment authorization in any of the following cases:

        <ul>
          <li>Card holder is not the donor, or the names do not match</li>
          <li>Address or phone number is not what the credit card, bank or payment service have on file for you</li>
          <li>Donor lives outside of the USA</li>
        </ul>

        <div class="instructions">
          Please click the Back button below to verify your information and try again.
        </div>
      </div>

    </mat-card-content>

  </mat-card>

  <div class="wizard-buttons" align="center">
    <button mat-button color="primary" (click)="cancel()">Cancel</button>
    <button color="primary" mat-raised-button (click)="goBack()">Back</button>
  </div>

</div>

