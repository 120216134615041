import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advisor-panel',
  templateUrl: './advisor-panel.component.html',
  styleUrls: ['./advisor-panel.component.scss']
})
export class AdvisorPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
