import { Component, OnInit, Input } from '@angular/core';
import * as mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import { MapService } from '../../../services/map.service';
import { Race } from '../../../types/race';
import { ElectionsService } from '../../../services/elections.service';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  public thematicOptions: string[] = ['Incumbent Party', '2016 Presidential Vote', 'Funding Advantage', 'Polling Advantage', 'Donation Impact'];
  public selectedThematicOption: string;

  // map members
  private map: mapboxgl.Map;
  private zoomThreshhold = 5;
  private renderedLayers: string[] = [];
  private hoveredFeatureId: null;

  // state and house race dictionaries, to speed up the client-side "join" of races with geojson data
  public stateRaces: Object = {};
  public houseRaces: Object = {};

  constructor(
    private mapService: MapService,
    private electionsService: ElectionsService) {
    this.selectedThematicOption = this.thematicOptions[0];
  }

  // #region Map setup

  private partitionRaces() {
    const filteredRaces = this.electionsService.electionsViewModel.filteredRaces;

    for (let race of filteredRaces) {
      if (race.typeCode === 'S') {
        this.stateRaces[race.raceState] = race;
      }
      else if (race.typeCode === 'H') {
        const key = race.raceState + '-' + race.district;
        this.houseRaces[key] = race;
      }
    }

  }

  ngOnInit() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZnVua21vbmtleTMzIiwiYSI6ImNrNmIxZmMwNjB3bXgzZG9iemJubmduYjYifQ.5JIKUQjgQ4W6jhDDgivBMw';
    this.partitionRaces();

    setTimeout(() => {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: "mapbox://styles/mapbox/light-v10", // stylesheet location
        center: [-95.5, 40], // starting position [lng, lat]
        zoom: 3 // starting zoom
      });

      this.map.on("load", () => {
        this.addStatesLayer();
        this.addCongressionalDistrictsLayer();
      });

      this.map.on('click', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: this.renderedLayers
        });

        // it looks like there will only be 1 feature in the array?
        if (features.length) {
          const feature = features[0];
          let race = null;

          if (feature.source === 'usstates') {
            const state = feature.properties.raceState;
            race = this.stateRaces[state];
          }
          else {
            const district = feature.properties.dist_code;
            race = this.houseRaces[district];
          }

          // this.electionsService.selectRace(race);
        }
      });

      // turn on mouse pointer for US only, outside of US gets the hand
      // mouse move on the whole map
      this.map.on("mousemove", (e) => {
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: this.renderedLayers
        });

        if (features.length > 0) {
          this.map.getCanvas().style.cursor = "pointer";
        }
        else {
          this.map.getCanvas().style.cursor = "";
        }
      });

      // #region hover-over effect on states
      // uses setFeatureState to set a hovered state, the layer has a style for that.
      // mouse move on just US States
      this.map.on('mousemove', 'usstates', (e) => {
        if (e.features.length > 0) {
          if (this.hoveredFeatureId) {
            this.map.setFeatureState(
              { source: 'usstates', id: this.hoveredFeatureId },
              { hover: false }
            );
          }
          this.hoveredFeatureId = e.features[0].id;
          this.map.setFeatureState(
            { source: 'usstates', id: this.hoveredFeatureId },
            { hover: true }
          );
        }
      });

      this.map.on('mouseleave', 'usstates', () => {
        if (this.hoveredFeatureId) {
          this.map.setFeatureState(
            { source: 'usstates', id: this.hoveredFeatureId },
            { hover: false }
          );
        }
        this.hoveredFeatureId = null;
      });

      // #endregion

      // #region hover-over effect on congressionalDistricts
      // mouse move on congressional districts
      this.map.on('mousemove', 'congressionalDistricts', (e) => {
        if (e.features.length > 0) {
          if (this.hoveredFeatureId) {
            this.map.setFeatureState(
              { source: 'congressionalDistricts', id: this.hoveredFeatureId },
              { hover: false }
            );
          }
          this.hoveredFeatureId = e.features[0].id;
          this.map.setFeatureState(
            { source: 'congressionalDistricts', id: this.hoveredFeatureId },
            { hover: true }
          );
        }
      });

      this.map.on('mouseleave', 'congressionalDistricts', () => {
        if (this.hoveredFeatureId) {
          this.map.setFeatureState(
            { source: 'congressionalDistricts', id: this.hoveredFeatureId },
            { hover: false }
          );
        }
        this.hoveredFeatureId = null;
      });

      // #endregion

    });
  }

  // #endregion

  // #region U.S. States Layer

  private joinStatesToRaces(geojson) {
    for (let i = 0; i < geojson.features.length; i++) {
      const feature = geojson.features[i];
      feature.id = i + 1;
      const race = this.stateRaces[feature.properties.STUSPS] as Race;

      if (race) {
        const raceProperties = {
          raceType: race.raceType,
          raceState: race.raceState,
          raceYear: race.raceYear,
          incumbentParty: race.incumbentParty === 'dem' ? 1 : race.incumbentParty === 'rep' ? 2 : 3
        };

        // todo add other thematic properties here
        feature.properties = { ...feature.properties, ...raceProperties };
      }
    }
  }

  private addStatesLayer()  {
    this.mapService.getStates().subscribe(geojson => {

      this.joinStatesToRaces(geojson);

      this.map.addSource('usstates', {
        type: 'geojson',
        data: geojson
      });

      this.map.addLayer({
        id: 'usstates',
        type: 'fill',
        source: 'usstates',
        layout: {},
        minzoom: 1,
        maxzoom: this.zoomThreshhold,
        paint: {
          'fill-opacity': 0.7,
          'fill-color': {
            property: "incumbentParty",
            stops: [
              [1, '#06839e'],
              [2, '#ce2a2a'],
              [3, 'green']
            ]
          },
        }
      });
      this.renderedLayers.push('usstates');
    });

    // todo: above: thematic styling based on some good thematic data that will make it's way into my races table
  }

  // #endregion

  // #region Congressional Districts Layer

  private joinCongressionalDistrictsToRaces(geojson) {
    for (let i = 0; i < geojson.features.length; i++) {
      const feature = geojson.features[i];
      feature.id = i + 1;
      const race = this.houseRaces[feature.properties.dist_code] as Race;

      if (race) {
        const raceProperties = {
          raceType: race.raceType,
          raceState: race.raceState,
          raceYear: race.raceYear,
          incumbentParty: race.incumbentParty === 'dem' ? 1 : race.incumbentParty === 'rep' ? 2 : 3
        };

        // todo add other thematic properties here
        feature.properties = { ...feature.properties, ...raceProperties };
      }
    }
  }
 
  private addCongressionalDistrictsLayer() {
    this.mapService.getCongressionalDistricts().subscribe(geojson => {

      this.joinCongressionalDistrictsToRaces(geojson);

      this.map.addSource('congressionalDistricts', {
        type: 'geojson',
        data: geojson
      });

      this.map.addLayer({
        id: 'congressionalDistricts',
        type: 'fill',
        source: 'congressionalDistricts',
        layout: {},
        minzoom: this.zoomThreshhold,
        maxzoom: 16,
        paint: {
          'fill-opacity': 0.6,
          'fill-color': {
            property: "incumbentParty",
            stops: [
              [1, '#06839e'],
              [2, '#b71212'],
              [3, 'green']
            ]
          },
          'fill-outline-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'yellow',
            'gray'
          ],
        }
      });
      this.renderedLayers.push('congressionalDistricts');

    });


  }

  // #endregion
}
