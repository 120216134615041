import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ElectionsService } from '../../services/elections.service';
import { MapService } from '../../services/map.service';
import { Race } from '../../types/race';
import { Candidate } from '../../types/candidate';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DonationDialogComponent } from './donation-dialog/donation-dialog.component';
import { DonationLine } from '../../types/donation-line';
import { CartService } from '../../services/cart-service';
import { CandidateInfoDialogComponent } from './candidate-info-dialog/candidate-info-dialog.component';
import { CandidateEditorDialogComponent } from './candidate-editor-dialog/candidate-editor-dialog.component';
import { ListComponent } from './list/list.component';
import { ElectionsViewModel, ElectionState } from '../../types/elections-view-model';
import { take, debounceTime, switchMap } from 'rxjs/operators';
import { RaceType } from '../../types/race-type';
import { ActiveParty } from '../../types/party';
import { FormGroup, FormControl } from '@angular/forms';
import { FavoritesService } from '../../services/favorites.service';
import { forkJoin, Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-elections',
  templateUrl: './elections.component.html',
  styleUrls: ['./elections.component.scss']
})
export class ElectionsComponent implements OnInit, OnDestroy {

  public electionsViewModel: ElectionsViewModel;
  public viewMode: string = 'list';
  public subviewMode: string = 'cardView';

  @ViewChild(ListComponent, {static: false})
  public listView: ListComponent;

  public filterForm: FormGroup;

  public sortOptions: any = [
    { title: "Donation Impact" },
    { title: "Polling Advantage" },
    { title: "Funding Advantage" },
  ];

  public selectedSortOption: any;

  public raceTypes: RaceType[];
  public activeParties: ActiveParty[];
  public electionStates: ElectionState[];

  public runningStatuses: any[] = [
    {
      value: null,
      label: '(All)'
    },
    {
      value: true,
      label: 'Yes'
    },
    {
      value: false,
      label: 'No'
    },
  ];

  public hasPictureValues: any[] = [
    {
      value: null,
      label: '(All)'
    },
    {
      value: true,
      label: 'Yes'
    },
    {
      value: false,
      label: 'No'
    },
  ];

  public get filterByFavorites(): boolean {
    return this.filterForm.controls.filterByFavorites.value as boolean;
  }

  public get isEditor() {
    return this.authService.isEditor();
  }

  private editCandidateRequestedSubscription: Subscription;
  private showMoreInfoRequestedSubscription: Subscription;
  private donationRequestedSubscription: Subscription;

  constructor(
    private electionsService: ElectionsService,
    private cartService: CartService,
    private mapService: MapService,
    private dialogService: MatDialog,
    public authService: AuthenticationService,
    private favoritesService: FavoritesService) {

    this.donationRequestedSubscription = this.electionsService.donationRequested.subscribe((candidate: Candidate) => {

      const dialogReference = this.dialogService.open(DonationDialogComponent, {
        width: '250px',
        data: candidate
      });

      dialogReference.afterClosed().subscribe((donationLine: DonationLine) => {
        if (donationLine) {
          this.cartService.addDonationToCart(donationLine);
        }
      });
    });

    this.showMoreInfoRequestedSubscription = this.electionsService.showMoreInfoRequested.subscribe((race: Race) => {
      const dialogReference = this.dialogService.open(CandidateInfoDialogComponent, {
        width: window.innerWidth > 1600 ?  '65%' : '80%',
        height: window.innerHeight > 1000 ? '75%': '90%',
        data: race
      });
    });

    this.editCandidateRequestedSubscription = this.electionsService.editCandidateRequested.subscribe((candidate: Candidate) => {
      const dialogReference = this.dialogService.open(CandidateEditorDialogComponent, {
        width: '800px',
        data: candidate
      });
      dialogReference.afterClosed().subscribe(() => {
        const formValue = this.filterForm.value;
        this.applyFilters(formValue);
      });
    });

  }

  ngOnInit() {

    // get a jump on data loading
    forkJoin(
      this.electionsService.getElectionModel().pipe(take(1)),
      this.favoritesService.getFavorites()
    )
    .subscribe(result => {
      const evm = result[0];
      this.electionsViewModel = evm;
      this.raceTypes = evm.raceTypes;
      this.activeParties = evm.activeParties;
      this.electionStates = evm.electionStates;


      this.initializeFilterForm();

      if (this.authService.isLoggedIn()) {
        this.electionsViewModel.pickFavorites(result[1])
      }
    });

    this.mapService.getStates().subscribe()
    this.mapService.getCongressionalDistricts().subscribe();

    // setup UI
    this.selectedSortOption = this.sortOptions[0];
  }

  private initializeFilterForm(): void {
    this.filterForm = new FormGroup({
      selectedRaceType: new FormControl(this.raceTypes[0]),
      selectedParty: new FormControl(this.activeParties[0]),
      selectedState: new FormControl(this.electionStates[0]),
      selectedCongressionalDistrict: new FormControl(null),
      selectedRunningStatus: new FormControl(this.runningStatuses[1]),
      selectedHasPictureValue: new FormControl(this.hasPictureValues[0]),
      searchText: new FormControl(''),
      filterByFavorites: new FormControl(false)
    });

    this.filterForm.valueChanges.pipe(debounceTime(200))
      .subscribe(val => {
        this.applyFilters(val);
      });

    this.filterForm.controls.selectedRaceType.valueChanges.subscribe(val => {
      this.filterForm.controls.selectedCongressionalDistrict.setValue('(All Districts)');
    });

    this.filterForm.controls.selectedState.valueChanges.subscribe((eState: ElectionState) => {
      this.availableDistricts = eState.districts;
      this.filterForm.controls.selectedCongressionalDistrict.setValue(this.availableDistricts[0]);
    });

    this.resetFilters();
  }

  public availableDistricts: string[];

  public isStateFilterVisible(): boolean {
    const selectedElectionType = this.filterForm.controls.selectedRaceType.value
    return selectedElectionType.code === 'S' || selectedElectionType.code === 'H';
  }

  public isDistrictFilterVisible(): boolean {
    const selectedElectionType = this.filterForm.controls.selectedRaceType.value;
    const selectedState = this.filterForm.controls.selectedState.value;
    return selectedElectionType.code === 'H' &&  selectedState.stateAbbreviation !== '';
  }

  private applyFilters(val) {
    this.electionsService.filter(val);

    if (val.selectedRaceType === this.raceTypes[0]) {
      this.filterForm.controls.selectedRaceType.markAsPristine();
    }

    if (val.selectedParty === this.activeParties[0]) {
      this.filterForm.controls.selectedParty.markAsPristine();
    }

    if (val.selectedRunningStatus == this.runningStatuses[1]) {
      this.filterForm.controls.selectedRunningStatus.markAsPristine();
    }

    if (val.searchText === '') {
      this.filterForm.controls.searchText.markAsPristine();
    }


  }

  public toggleFavorites(val: boolean) {
    this.filterForm.controls.filterByFavorites.setValue(val);
    this.filterForm.controls.filterByFavorites.markAsDirty();
  }

  public showList() {
    this.subviewMode = 'listView';
  }

  public showCards() {
    this.subviewMode = 'cardView'
  }

  public resetFilters(): void {
    this.filterForm.setValue({
      selectedRaceType: this.raceTypes[0],
      selectedParty: this.activeParties[0],
      selectedState: this.electionStates[0],
      selectedCongressionalDistrict: null,
      selectedRunningStatus: this.runningStatuses[1],
      selectedHasPictureValue: this.hasPictureValues[0],
      searchText: '',
      filterByFavorites: false
    });
    this.filterForm.markAsPristine();
    
  }

  ngOnDestroy() {
    if (this.donationRequestedSubscription) {
      this.donationRequestedSubscription.unsubscribe();
    }

    if (this.editCandidateRequestedSubscription) {
      this.editCandidateRequestedSubscription.unsubscribe();
    }

    if(this.showMoreInfoRequestedSubscription) {
      this.showMoreInfoRequestedSubscription.unsubscribe();
    }
  }

}
