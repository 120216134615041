import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartyAffinity, PartySortOption  } from '../types/lookups';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LookupsService {

  private partyAffinities: PartyAffinity[];
  private partySortOptions: PartySortOption[];

  constructor(private http: HttpClient) { }

  public getPartyAffinities(): Observable<PartyAffinity[]> {
    if (this.partyAffinities) {
      return of(this.partyAffinities);
    }
    else {
      const url = 'api/lookups/PartyAffinities';
      return this.http.get<PartyAffinity[]>(url).pipe(
        tap(result => {
          this.partyAffinities = result;
        })
      );
    }
  }

  public getPartySortOptions(): Observable<PartySortOption[]> {
    if (this.partySortOptions) {
      return of(this.partySortOptions);
    }
    else {
      const url = 'api/lookups/PartySortOptions';
      return this.http.get<PartySortOption[]>(url).pipe(
        tap(result => {
          this.partySortOptions = result;
        })
      );
    }
  }

}
