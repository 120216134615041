<div class="login-container" >

  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Login">
      <acc-login #login></acc-login>
    </mat-tab>
    <mat-tab label="New User Registration">
      <acc-new-user-registration #newUserRegistration></acc-new-user-registration>
    </mat-tab>
  </mat-tab-group>

</div>


