import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountModule } from './account/account.module';
import { LoginContainerComponent } from './account/login-container/login-container.component';
import { ElectionsComponent } from './elections/components/elections/elections.component';
import { ActivateComponent } from './account/activate/activate.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { CheckoutContainerComponent } from './elections/components/checkout-container/checkout-container.component';
import { DonationSuccessComponent } from './elections/components/checkout-container/donation-success/donation-success.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ProfileComponent } from './account/profile/profile.component';
import { PrivacyPolicyComponent } from './about/privacy-policy/privacy-policy.component';
import { HowBlunamiWorksComponent } from './about/how-blunami-works/how-blunami-works.component';
import { OrganizeComponent } from './about/organize/organize.component';

const routes: Routes = [
  {
    path: 'activate',
    component: ActivateComponent
  },
   {
    path: 'login',
    component: LoginContainerComponent
  },
  {
    path: 'login/:returnUrl',
    component: LoginContainerComponent
  },
  {
    path: 'account/profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'elections',
    component: ElectionsComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'about/privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'about/how-blunami-works',
    component: HowBlunamiWorksComponent
  },
  {
    path: 'about/organize',
    component: OrganizeComponent
  },
  {
    path: 'checkout',
    component: CheckoutContainerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'checkout/success',
    component: DonationSuccessComponent
  },
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
