import { Injectable } from '@angular/core';
import { NewUserRequestViewModel } from '../types/new-user-request.view-model';
import { NewUserResponseViewModel } from '../types/new-user-response.view-model';
import { ActivateUserRequestViewModel } from '../types/activate-user-request.view-model';
import { ActivateUserResponseViewModel } from '../types/activate-user-response.view-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  public createAccount(vm: NewUserRequestViewModel): Observable<NewUserResponseViewModel> {
    const url = 'api/account/register';
    return this.http.post<NewUserResponseViewModel>(url, vm);
  }

  public activateUser(vm: ActivateUserRequestViewModel): Observable<ActivateUserResponseViewModel> {
    const url = 'api/account/activate';
    return this.http.post<ActivateUserResponseViewModel>(url, vm);
  }
}
