import { Injectable } from '@angular/core';
import { VoterInfo } from '../types/voterInfo';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VoterInfoService {
  private _voterInfo: VoterInfo;
  constructor(private http: HttpClient) { }

  public getVoterInfo(): Observable<VoterInfo> {
    const url = 'api/profile/getVoterInfo';
    return this.http.get<VoterInfo>(url).pipe(
      tap(vi => {
        this._voterInfo = vi;
      })
    );
  }

  public saveVoterInfo(voterInfo: VoterInfo): Observable<VoterInfo> {
    const url = 'api/profile/saveVoterInfo';

    return this.http.post<VoterInfo>(url, voterInfo).pipe(
      tap(vi => {
        this._voterInfo = vi;
      })
    );
  }

  public clear(): void {
    this._voterInfo = null;
  }
}
