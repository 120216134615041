import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ElectionsService } from '../../../services/elections.service';
import { Candidate } from '../../../types/candidate';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReplaceCandidateImageDialogComponent } from './replace-candidate-image-dialog/replace-candidate-image-dialog.component';

@Component({
  selector: 'app-candidate-editor-dialog',
  templateUrl: './candidate-editor-dialog.component.html',
  styleUrls: ['./candidate-editor-dialog.component.scss']
})
export class CandidateEditorDialogComponent implements OnInit {

  candidateEditForm: FormGroup;

  public wikipediaRatings: any[] = [
    {
      value: 1,
      title: 'Safe'
    },
    {
      value: 2,
      title: 'Lean'
    },
    {
      value: 3,
      title: 'Tilt'
    },
    {
      value: 4,
      title: 'Likely'
    },
    {
      value: 5,
      title: 'Toss-up'
    },

  ];

  constructor(
    private electionService: ElectionsService,
    public dialogRef: MatDialogRef<CandidateEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public candidate: Candidate,
    private dialogService: MatDialog
    ) { }

  ngOnInit() {
    this.candidateEditForm = new FormGroup({
      headshot: new FormControl(this.candidate.headshot),
      isRunning: new FormControl(this.candidate.isRunning),
      nickname: new FormControl(this.candidate.nickname),
      fiveThirtyEightName: new FormControl(this.candidate.fiveThirtyEightName),
      wikipediaRating: new FormControl(this.candidate.wikipediaRating),
      paypalAccountEmail: new FormControl(this.candidate.paypalAccountEmail, Validators.email)
    });
  }

  public replaceImage(e: Event): void {
    e.preventDefault();

    const dialogReference = this.dialogService.open(ReplaceCandidateImageDialogComponent, {
      width: '300px',
      data: this.candidate
    });

    dialogReference.afterClosed().subscribe((candidate: Candidate) => {
      const headshotContol = this.candidateEditForm.controls.headshot;
      headshotContol.setValue(candidate.headshot);
      headshotContol.markAsDirty();
    });

  }

  public saveCandidate() {
    const formValue = this.candidateEditForm.value;

    this.candidate.isRunning = formValue.isRunning;
    this.candidate.nickname = formValue.nickname;
    this.candidate.fiveThirtyEightName = formValue.fiveThirtyEightName;
    this.candidate.wikipediaRating = formValue.wikipediaRating;
    this.candidate.paypalAccountEmail = formValue.paypalAccountEmail;

    this.electionService.saveCandidate(this.candidate).subscribe(
      result => {
        
      },
      err => {
        console.error(err);
      }
    );
  }

  public cancel(): void {
    this.dialogRef.close();
  }

}
