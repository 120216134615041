<div class="activate-pending" *ngIf="status === 'pending'">
  <div class="spinner-display">
    <mat-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="4" [color]="'primary'" ></mat-spinner>
    <div class="label">Working...</div>
  </div>
</div>

<div class="container activate-container" *ngIf="status === 'success'">
  <div class="row">
    <div class="col-12">

      <div class="mat-display-1">Activation Complete</div>

      <div class="mat-body-1">
        Thank you for confirming your email.  Your account is now activated.  Please <a href="/login?returnUrl=account/profile">click here to log in</a>.
      </div>

    </div>
  </div>
</div> 

<div class="container activate-container" *ngIf="status === 'failed'">
  <div class="row">
    <div class="col-12">

      <div class="mat-display-1">Activation Failed</div>

      <div class="mat-body-1">
        We were not able to activate your account.
        <span *ngIf="reason === 'data-missing'">If you copied the link from your email, please make sure you copied the entire url.</span>
      </div>

    </div>
  </div>

</div>
