export class RaceType {
  public code: string;
  public name: string;

  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }

  static getRaceTypes(): RaceType[] {
    return [
      new RaceType('', '(All Race Types)'),
      new RaceType('S', 'U.S. Senate'),
      new RaceType('H', 'U.S. House'),
      new RaceType('P', 'President')];
  }
}
