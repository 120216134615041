<div id="map" style="height: 100%; width: 100%; ">

  <div id="legend" class="legend">
    <mat-form-field color="primary">
      <mat-select color="primary" [(ngModel)]="selectedThematicOption">
        <mat-option *ngFor="let thematicOption of thematicOptions" [value]="thematicOption" >{{thematicOption}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div><span style="background-color: #06839e"></span>Democrat</div>
    <div><span style="background-color: #ce2a2a"></span>Republican</div>
    <div><span style="background-color: green"></span>Independent</div>
    <div><span style="background-color: #333"></span>No 2020 Election</div>
  </div>

</div>

