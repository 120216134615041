import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DonationLine } from '../../../types/donation-line';
import { Candidate } from '../../../types/candidate';

@Component({
  selector: 'app-donation-dialog',
  templateUrl: './donation-dialog.component.html',
  styleUrls: ['./donation-dialog.component.scss']
})
export class DonationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DonationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public donationLine: DonationLine
    ) { }

  ngOnInit() {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public enterPressed(): void {
    if (this.donationLine.amount > 0) {

      this.dialogRef.close(this.donationLine);
    }
  }
}
