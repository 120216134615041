import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Favorite } from '../types/favorite';
import { map, tap, take } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  private favorites: Favorite[];

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) {
  }

  public getFavorites(): Observable<Favorite[]> {
    const isLoggedIn: boolean = this.authService.isLoggedIn();

    if (this.favorites || !isLoggedIn) {
      return of(this.favorites);
    }
    else {
      const url = 'api/favorites';
      return this.http.get<Favorite[]>(url)
        .pipe(
          tap(favorites => {
            this.favorites = favorites;
          })
        );
    }
  }

  addFavorite(candidateId: number): Observable<Favorite> {
    const url = 'api/favorites/' + candidateId;
    const body = {
      candidateId: candidateId
    };

    // todo: add this to my favorites dictionary :)
    return this.http.post<Favorite>(url, body).pipe(
      tap(fav => {        
        this.favorites.push(fav);
      })
    );
  }

  deleteFavorite(candidateId: number): Observable<boolean> {
    const url = 'api/favorites/' + candidateId;

    return this.http.delete<boolean>(url).pipe(
      tap(success => {
        this.favorites = this.favorites.filter(f => f.candidateId !== candidateId);
      })
    );
  }
}
