<div class="blu-donate-list-view">
  <div class="list-panel" #listPanel>
    <app-candidate-card *ngFor="let candidate of currentPageOfCandidates; trackBy: trackByCandidateId" [candidate]="candidate" [isLoggedIn]="isLoggedIn" ></app-candidate-card>
  </div>

  <div class="list-control-panel" *ngIf="currentPageNumber !== undefined">
    <div class="list-controls">
      <button mat-mini-fab (click)="goFirst()" [disabled]="currentPageNumber === 0">
        <mat-icon>skip_previous</mat-icon>
      </button>
      <button mat-mini-fab (click)="goPrevious()" [disabled]="currentPageNumber === 0">
        <mat-icon>navigate_before</mat-icon>
      </button>

      <div class="page-info">Page {{currentPageNumber + 1}} of {{numberOfPages}}</div>

      <button mat-mini-fab (click)="goNext()" [disabled]="currentPageNumber === numberOfPages - 1">
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button mat-mini-fab (click)="goLast()" [disabled]="currentPageNumber === numberOfPages - 1">
        <mat-icon>skip_next</mat-icon>
      </button>

    </div>
  </div>

</div>


