import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Blunami';
  public firstName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    this.firstName = this.authenticationService.getUserFirstName();

    $('.navbar-nav>li>a:not(.dropdown-toggle), .blu-logo, .dropdown-item').on('click', function () {
      $('.navbar-collapse').collapse('hide');
      $('.blu-custom-scrollbar').scrollTop(0);
    });
  }

  public isLoggedIn() {
    return this.authenticationService.isLoggedIn();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

  
}
