import { Component, OnInit, Input } from '@angular/core';
import { Candidate } from '../../../../types/candidate';
import { take } from 'rxjs/operators';
import { FavoritesService } from '../../../../services/favorites.service';

@Component({
  selector: 'app-favorite-display',
  templateUrl: './favorite-display.component.html',
  styleUrls: ['./favorite-display.component.scss']
})
export class FavoriteDisplayComponent implements OnInit {

  @Input()
  public candidate: Candidate;

  @Input()
  public isLoggedIn: boolean;

  public isBusy: boolean = false;

  constructor(private favoritesService: FavoritesService ) { }

  ngOnInit() {
  }

  public addToFavorites() {
    if (!this.isLoggedIn) {
      return;
    }
    this.isBusy = true;

    this.favoritesService.addFavorite(this.candidate.id).pipe(take(1)).subscribe(result => {
      this.candidate.isFavorite = true;
      this.isBusy = false;
    }, err => {
      this.isBusy = false;
    });

  }

  public removeFromFavorites() {
    if (!this.isLoggedIn) {
      return;
    }
    this.isBusy = true;
    this.favoritesService.deleteFavorite(this.candidate.id).pipe(take(1)).subscribe(success => {
      if (success) {
        this.candidate.isFavorite = false;
        this.isBusy = false;
      }
    });

  }

}
