import { DonationLine } from './donation-line';
import { Candidate } from './candidate';

export class CartModel {
  public donationLines: DonationLine[]; 
  public totalDonation: number;

  public get isEmpty(): boolean {
    const empty = this.donationLines.length === 0;
    return empty;
  }

  constructor() {
    this.initCart();
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  private initCart() {
    this.donationLines = [];
    this.totalDonation = 0;
  }

  public addToCart(donation: DonationLine): void {
    let line = this.donationLines.find(l => l.candidate == donation.candidate);
    let oldAmount: number = 0;

    if (!line) {
      this.donationLines.push(donation);
    }
    else {
      if (donation.isEdit) {
        oldAmount = line.amount;
        line.amount = donation.amount;
        donation.isEdit = false;
      }
      else {
        line.amount += donation.amount;
      }
      
    }

    this.totalDonation += donation.amount;
    this.totalDonation -= oldAmount;
  }

  public removeFromCart(donationLine: DonationLine): void {
    this.totalDonation -= donationLine.amount;
    this.donationLines = this.donationLines.filter(c => c !== donationLine);
  }

  public clear() {
    this.initCart();
  }

  private getLine(candidate: Candidate): DonationLine {
    return this.donationLines.find(l => l.candidate === candidate);
  }

  public isRaceInCart(candidate: Candidate): boolean {
    return this.getLine(candidate) != null;
  }


}
