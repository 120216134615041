import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { ActivateUserRequestViewModel } from '../types/activate-user-request.view-model';
import { ActivateUserResponseViewModel } from '../types/activate-user-response.view-model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
  public status: string = 'pending';
  public reason: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService) { }

  ngOnInit() {
    const email: string = this.activatedRoute.snapshot.queryParamMap.get('userId');
    const code: string = this.activatedRoute.snapshot.queryParamMap.get('code');

    if (!email || !code) {
      this.status = 'failed';
      this.reason = 'data-missing';
    }
    else {
      const vm = new ActivateUserRequestViewModel(email, code);
      const subscription: Subscription = this.accountService.activateUser(vm)
        .subscribe(result => {
          this.status = result.wasSuccessful ? 'success' : 'failed';
          this.reason = result.reason;
          subscription.unsubscribe();
        });
    }

  }

}
