import { Component, OnInit} from '@angular/core';
import { ElectionsService } from '../../../../services/elections.service';
import { Candidate } from '../../../../types/candidate';
import { DonationLine } from '../../../../types/donation-line';

@Component({
  selector: 'app-candidate-info-panel',
  templateUrl: './candidate-info-panel.component.html',
  styleUrls: ['./candidate-info-panel.component.scss']
})
export class CandidateInfoPanelComponent implements OnInit {

  public get selectedCandidate(): Candidate {
    if (!this.electionsService.electionsViewModel) {
      return undefined;
    }
    return this.electionsService.electionsViewModel.activeSelectedCandidate;
  }

  constructor(private electionsService: ElectionsService) { }

  ngOnInit() {
  }

  public donate() {
    const blankDonation: DonationLine = new DonationLine();
    blankDonation.candidate = this.selectedCandidate;
    this.electionsService.requestDonation(blankDonation);
  }

  public showMoreInfo() {
    this.electionsService.showMoreInfo(this.selectedCandidate);
  }

}
