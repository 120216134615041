import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  // layers
  private stateGeoJson: string;
  private congressionalDistrictsJson: string;

  constructor(private http: HttpClient) { }

  public getStates(): Observable<any> {
    if (this.stateGeoJson) {
      return of(this.stateGeoJson);
    }
    else {
      return this.http.get<any>('geojson/us_states.json').pipe(
        tap(result => {
          this.stateGeoJson = result;
        })
      );
    }
  }

  public getCongressionalDistricts(): Observable<string> {
    if (this.congressionalDistrictsJson) {
      return of(this.congressionalDistrictsJson);
    }
    else {
      return this.http.get<string>('geojson/116_con_dist_with_attributes.json').pipe(
        tap(result => {
          this.congressionalDistrictsJson = result;
        })
      );
    }
  }

}
