<div [formGroup]="voterInfoForm" *ngIf="voterInfoForm">

  <p class="mat-body-strong">
    The information below is required by the FEC for all political contributions.  <strong>Blunami does not give, sell or rent this information for any purpose other than compliance with the law.</strong>
  </p>

  <mat-form-field style="width: 100%;">
    <mat-label>Address Line 1</mat-label>
    <input matInput placeholder="Street Address 1" formControlName="address1" #address1 tabindex="1" />
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>Address Line 2</mat-label>
    <input matInput placeholder="Street Address 2" formControlName="address2" tabindex="1" />
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>City</mat-label>
    <input matInput placeholder="City" formControlName="city" tabindex="1" />
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>State</mat-label>
    <input matInput placeholder="State" formControlName="state" tabindex="1" />
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>Zip Code</mat-label>
    <input matInput placeholder="Zip Code" formControlName="zip" tabindex="1" />
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>Phone</mat-label>
    <input matInput placeholder="Phone" formControlName="phone" tabindex="1" />
  </mat-form-field>

</div>
