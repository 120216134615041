<mat-card *ngIf="!isAccountCreated" class="new-account-panel-card" [formGroup]="newAccountRegistrationForm">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>person_outline</mat-icon>
    </div>
    <mat-card-title>New User</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field style="width: 100%;">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="First Name" #firstNameField formControlName="firstName" />
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="Last Name" formControlName="lastName" />
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Enter Email" formControlName="email" />
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="Enter Password" formControlName="password" />
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Confirm Password</mat-label>
      <input matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword" />
    </mat-form-field>

    <div class="error-messages-panel">
      {{newAccountRegistrationForm?.errors?.error}}
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="createAccount()" [disabled]="!newAccountRegistrationForm.valid" style="margin-bottom: 20px;">Create Account</button>
  </mat-card-actions>

</mat-card>

<acc-email-confirmation *ngIf="isAccountCreated" [email]="emailUsed"></acc-email-confirmation>
