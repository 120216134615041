<h2 mat-dialog-title>{{donationLine.candidate.bestName}}</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Donation Amount</mat-label>
    <input matInput [(ngModel)]="donationLine.amount" type="number" tabindex="1" (keyup.enter)="enterPressed()" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button color="accent" (click)="cancel()" tabindex="2">Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="donationLine" tabindex="3" [disabled]="!donationLine.amount || donationLine.amount < 0">Ok</button>
</div>
