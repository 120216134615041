import { Injectable } from '@angular/core';
import { CartModel } from '../types/cart-model';
import { Race } from '../types/race';
import { DonationLine } from '../types/donation-line';
import { Candidate } from '../types/candidate';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cartModel: CartModel;

  public get totalDonation() {
    return this.cartModel.totalDonation;
  }

  public get donationLines(): DonationLine[] {
    return this.cartModel.donationLines;
  }

  public cartIsEmpty(): boolean {
    return this.cartModel.isEmpty;
  }

  constructor() {

    const savedCartModel = this.loadCartFromLocalStorage();

    if (savedCartModel) {
      this.cartModel = new CartModel().deserialize(savedCartModel);
    }
    else {
      this.cartModel = new CartModel();
    }
  }

  public addDonationToCart(donation: DonationLine) {
    this.cartModel.addToCart(donation);
    this.saveCartToLocalStorage();
  }

  public removeFromCart(donation: DonationLine) {
    this.cartModel.removeFromCart(donation);
    this.saveCartToLocalStorage();
  }

  public clearCart(): void {
    this.cartModel.clear();
    this.saveCartToLocalStorage();
  }

  public isCandidateInCart(candidate: Candidate): boolean {
    return this.cartModel.isRaceInCart(candidate);
  }

  private saveCartToLocalStorage(): void {
    if (this.cartModel) {
      localStorage['cart'] = JSON.stringify(this.cartModel);
    }
  }

  private loadCartFromLocalStorage(): CartModel {
    const cartString = localStorage['cart'];

    if (cartString) {
      return JSON.parse(cartString);
    }
  }

  public getCartSummary(): string {
    return `${this.totalDonation.toLocaleString('en-US', {style: 'currency', currency: 'USD'})} to ${this.donationLines.length} candidates`;
  }
}
