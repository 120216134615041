<div class="blu-account-navs">

  <a class="blu-dropdown-link" [ngClass]="{'active': isRouteActive()}">
    <mat-icon>person</mat-icon>
    <span>HI, {{firstName?.toUpperCase()}}</span>
    <mat-icon>expand_more</mat-icon>
  </a>

  <ul class="blu-nav-dropdown">
    <li>
      <a [routerLink]="'/account/profile'">
        <mat-icon>info</mat-icon>
        <span class="mat-subheading-2">Profile</span>
      </a>
    </li>
    <li>
      <a [routerLink]="'/account/change-password'">
        <mat-icon>lock</mat-icon>
        <span class="mat-subheading-2">Change Password</span>
      </a>
    </li>
    <li class="last-item">
      <a (click)="logout()">
        <mat-icon>remove_circle</mat-icon>
        <span class="mat-subheading-2">Log Out</span>
      </a>
    </li>
  </ul>

</div>

