import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ActivateComponent } from './activate/activate.component';
import { RouterModule } from '@angular/router';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LoginComponent } from './login-container/login/login.component';
import { NewUserRegistrationComponent } from './login-container/new-user-registration/new-user-registration.component';
import { AccountPanelComponent } from './account-panel/account-panel.component';
import { ProfileComponent } from './profile/profile.component';
import { PreferencesComponent } from './profile/preferences/preferences.component';
import { DonationHistoryComponent } from './profile/donation-history/donation-history.component';
import { CampaignsComponent } from './profile/campaigns/campaigns.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [LoginComponent, EmailConfirmationComponent, ActivateComponent, LoginContainerComponent, NewUserRegistrationComponent, AccountPanelComponent, ProfileComponent, PreferencesComponent, DonationHistoryComponent, CampaignsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  exports: [LoginComponent, EmailConfirmationComponent, AccountPanelComponent]
})
export class AccountModule { }
