import { EventEmitter, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Bluser } from '../types/bluser';
import { TokenRequestViewModel } from '../types/token-request.view-model';
import { TokenResponseViewModel } from '../types/token-response.view-model';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  clientId: string = "blunami";
  currentUser: Bluser;
  private jwtHelper: JwtHelperService;

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID)
    private platformId: any)
  {
    this.jwtHelper = new JwtHelperService();
  }

  login(vm: TokenRequestViewModel): Observable<any> {
    var url = 'api/account/auth';

    // todo: what is all this other crap?

    var data = {
      username: vm.username,
      password: vm.password,
      client_id: this.clientId,

      // required when signing up with a username/password
      grant_type: 'password',
      scope: "offline-access profile email"
    };

    return this.http.post<string>(url, data)
      .pipe(
        map(token => {

          if (token) {
            this.setAuth(token);
            return true;
          }

          // failed login
          return Observable.throw('Unauthorized');
        })        
      )
  }

  logout(): boolean {
    this.setAuth(null);
    this.currentUser = null;
    return true;
  }

  private setAuth(token: string): void {
    if (token) {
      sessionStorage.setItem('authToken', token);
    }
    else {
      sessionStorage.removeItem('authToken');
    }
  }

  getAuthToken(): string {
    const authToken = sessionStorage.getItem('authToken');

    try {
      if (authToken) {
        if (!this.jwtHelper.isTokenExpired(authToken)) {
          return authToken;
        }
      }

      return null;
    }
    catch (error) {
      return null
    }
  }

  public isLoggedIn(): boolean {
    return this.getAuthToken() != null;
  }

  private getJwtClaimValue(fieldName: string): string {
    const authToken = this.getAuthToken();

    if (authToken) {
      const jwtData = jwt_decode(authToken);

      if (jwtData) {
        const claimValue = jwtData[fieldName];

        if (claimValue && claimValue.length) {
          return claimValue;
        }
      }
    }

    return '';
  }

  public isEditor(): boolean {
    const isEditorClaim = this.getJwtClaimValue('IsEditor');
    return isEditorClaim === "true";
  }

  getUserFirstName(): string {
    return this.getJwtClaimValue('given_name');
  }

  getUserName(): string | null {
    return this.getJwtClaimValue('given_name') + ' ' + this.getJwtClaimValue('family_name');
  }

  getUserId(): string {
    return this.getJwtClaimValue('UserId');
  }

  public getCurrentUser(): Bluser {
    return this.currentUser;
  }

}
