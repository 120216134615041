import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'acc-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  @Input()
  public email: string;

  constructor() { }

  ngOnInit() {
  }

}
