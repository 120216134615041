<mat-card class="login-panel-card" [formGroup]="loginForm">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>person_outline</mat-icon>
    </div>
    <mat-card-title>User Login</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field style="width: 100%;">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Enter Email" formControlName="email" #emailInput tabindex="1" />
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="Enter Password" formControlName="password" tabindex="2" (keyup.enter)="enterPressed()" />
    </mat-form-field>

    <div class="error-messages-panel">
      {{loginForm?.errors?.auth}}
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button [disabled]="!loginForm.valid" (click)="login()">Login</button>
  </mat-card-actions>

  <div class="forgot-password-link">
    <a href="/forgot-password">Forgot Password?</a>
  </div>

</mat-card>
