<mat-card *ngIf="totalDonation">
  <mat-card-header>
    <div class="blu-cart-header">
      <img src="../../assets/images/Donkey_Cart.svg" />
      <div class="cart-total">
        {{totalDonation | currency}}
      </div>
    </div>
    
  </mat-card-header>

  <mat-card-content class="blu-custom-scrollbar cart">
    <div class="donation" *ngFor="let donationLine of donationLines">
      <div class="cart-edit-buttons">
        <mat-icon (click)="editDonation(donationLine)"
                  matTooltip="Edit donation" matTooltipShowDelay="500" matTooltipHideDelay="300" matTooltipPosition="below">edit</mat-icon>
        <mat-icon (click)="removeDonation(donationLine)"
                  matTooltip="Remove donation" matTooltipShowDelay="500" matTooltipHideDelay="300" matTooltipPosition="below">delete</mat-icon>
      </div>
      <div class="candidate-name-and-party">
        <span class="candidate-name">{{donationLine.candidate.bestName}}</span>
        <span class="party-icon"
              [matTooltip]="donationLine.candidate.party.name" matTooltipShowDelay="500" matTooltipHideDelay="300" matTooltipPosition="below"
              [ngClass]="{'democrat': donationLine.candidate.party.code === 'DEM', 'republican': donationLine.candidate.party.code === 'REP'}">{{donationLine.candidate.party.code}}
        </span>
      </div>
      <div class="donation-amount">
        {{donationLine.amount| currency}}
      </div>
    </div>

    <div class="empty-cart" *ngIf="!totalDonation">Your Cart is Empty.</div>

  </mat-card-content>

  <mat-card-actions>
    <button mat-button color="accent" [disabled]="!totalDonation" (click)="clearDonations()" >Clear</button>
    <a mat-button color="primary" [disabled]="!totalDonation" [routerLink]="'/checkout'" >Checkout</a>
  </mat-card-actions>

</mat-card>
