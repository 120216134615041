<div class="empty-cart-container">
  <mat-card class="empty-cart-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>shopping_cart</mat-icon>
      </div>
      <mat-card-title>Empty Cart</mat-card-title>
    </mat-card-header>

    <mat-card-content style="height: 200px;">
      <p class="mat-body-strong" style="font-size: 18px; line-height: 1.8em;">
        Your cart is empty.  Click the Elections button below to find candidates to donate to.
      </p>
    </mat-card-content>

    <mat-card-actions style="text-align: right;">
      <button mat-raised-button (click)="gotoElections()">Elections</button>
    </mat-card-actions>

  </mat-card>

</div>
