import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Candidate } from '../../../../types/candidate';
import { DonationLine } from '../../../../types/donation-line';
import { CartService } from '../../../../services/cart-service';
import { ElectionsService } from '../../../../services/elections.service';

@Component({
  selector: 'app-opponent',
  templateUrl: './opponent.component.html',
  styleUrls: ['./opponent.component.scss']
})
export class OpponentComponent implements OnInit {

  @Input() opponent: Candidate;
  @Input() opponentOf: Candidate;

  @Output()
  public candidateChanged: EventEmitter<Candidate> = new EventEmitter<Candidate>();

  constructor(
    private cartService: CartService,
    private electionsService: ElectionsService) { }

  ngOnInit() {
  }

  public donate(amt: number, e: Event) {
    const donationLine = new DonationLine();
    donationLine.candidate = this.opponent;
    donationLine.amount = amt;
    this.cartService.addDonationToCart(donationLine);
  }

  public donateUnspecified(e: Event) {
    const blankDonationLine: DonationLine = new DonationLine();
    blankDonationLine.candidate = this.opponent;
    this.electionsService.requestDonation(blankDonationLine);
  }

  public gotoCandidate(): void {
    this.candidateChanged.emit(this.opponent);
  }
}
