import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss']
})
export class CampaignEditComponent implements OnInit {

  public selectedRecurrence = 'weekly';

  constructor(private campiagnService: CampaignService) { }

  ngOnInit() {
  }

}
