<div class="opponent-panel">

  <div class="opponent-panel-header">

    <div class="opponent-identity">

      <div class="opponent-icon">
        <img *ngIf="opponent?.headshot" src="images/candidates/thumbs/{{opponent.headshot}}" />
        <mat-icon *ngIf="!opponent?.headshot" color="primary">person_outline</mat-icon>
      </div>

      <div class="identity-info">
        <span class="opponent-name" (click)="gotoCandidate()">{{opponent?.bestName}}</span>
        <span class="opponent-party" [ngClass]="{'democrat': opponent.party.id === 19, 'republican': opponent.party.id === 74}">
          {{opponent?.party.name}}
        </span>
        <span><span *ngIf="opponent?.incumbentChallengerStatus == 'I'" style="font-style: italic;"> (Incumbent)</span></span>
        <!--<div class="opponent-office">{{selectedRace?.raceType}}</div>-->
        <!--<div class="race-info">
        <span>{{selectedRace.stateName}}</span>
        <span *ngIf="selectedRace.typeCode === 'H'">, District {{selectedRace.district}}</span>
      </div>-->
      </div>

    </div>

    <div class="opponent-donation-stuff">
      <div class="donation-buttons">
        <button mat-stroked-button color="primary" (click)="donate(1, $event)">$1</button>
        <button mat-stroked-button color="primary" (click)="donate(5, $event)">$5</button>
        <button mat-stroked-button color="primary" (click)="donate(10, $event)">$10</button>
        <button mat-stroked-button color="primary" (click)="donateUnspecified($event)">$</button>
      </div>
      <!--<div class="donation-history">
      You have donated $25 to this candidate
    </div>
    <div class="campaign-membership">
      Part of campaign
      <span class="campaign-name">
        <a href="">Swing Revival</a>
      </span>
    </div>-->
      <div class="donation-impact">
        <div class="star-rating">
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
        </div>

      </div>
    </div>

  </div>

  <div class="opponent-panel-body">
    <table class="opponent-funding-info">
      <thead>
        <tr>
          <td>
            <h3>Funding</h3>
          </td>
          <td>
            
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="label">
            Cash on Hand:
          </td>
          <td class="value">
            {{opponent.endingCash | currency : 'USD' : 'symbol' : '1.0-0'}}
          </td>
        </tr>
        <tr>
          <td class="label">
            Total Raised:
          </td>
          <td class="value">
            {{opponent.totalReceipts | currency : 'USD' : 'symbol' : '1.0-0'}}
          </td>
        </tr>
        <tr>
          <td class="label">
            Total Spent:
          </td>
          <td class="value">
            {{opponent.totalDispursements | currency : 'USD' : 'symbol' : '1.0-0'}}
          </td>
        </tr>

      </tbody>

    </table>

    <div class="opponent-polling-info">

      <h3>Polling</h3>

      <div class="polling-info" style="margin-left: 120px; width: 300px;">
        <div class="label">FiveThirtyEight:</div>
        <div class="value">+ 10%</div>

        <div class="label">Huff Post:</div>
        <div class="value">+ 7%</div>

        <div class="label">Real Clear Politics:</div>
        <div class="value">-19%</div>

      </div>

    </div>

  </div>



</div>

