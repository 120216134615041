import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AccountModule } from './account/account.module';
import { ElectionsModule } from './elections/elections.module';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AuthInterceptor } from './services/auth.interceptor.service';
import { AuthGuardService } from './services/auth-guard.service';
import { SharedModule } from './shared/shared.module';
import { PrivacyPolicyComponent } from './about/privacy-policy/privacy-policy.component';
import { HowBlunamiWorksComponent } from './about/how-blunami-works/how-blunami-works.component';
import { OrganizeComponent } from './about/organize/organize.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    PrivacyPolicyComponent,
    HowBlunamiWorksComponent,
    OrganizeComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule, 
    BrowserAnimationsModule,    
    AccountModule,
    ElectionsModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
