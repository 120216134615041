<div class="blu-elections-panel">

  <div class="blu-toolbar">

    <div class="left-side">

      <mat-button-toggle-group class="map-list-buttons" name="viewMode" aria-label="View Mode" [(value)]="viewMode" style="margin-top: 10px;">
        <mat-button-toggle value="list">Candidates</mat-button-toggle>
        <mat-button-toggle value="map">Races</mat-button-toggle>
      </mat-button-toggle-group>

      <div class="filter-container" *ngIf="filterForm" [formGroup]="filterForm">

        <div class="race-type-filter">

          <div class="filter-label">
            Race Type
          </div>

          <mat-form-field color="primary" appearance="outline">
            <mat-select color="primary" disableOptionCentering="true" formControlName="selectedRaceType">
              <mat-option *ngFor="let raceType of raceTypes" [value]="raceType">{{raceType.name}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div *ngIf="isStateFilterVisible()">
          <div class="filter-label">
            State
          </div>

          <mat-form-field color="primary" appearance="outline">
            <mat-select color="primary" disableOptionCentering="true" formControlName="selectedState">
              <mat-option *ngFor="let eState of electionStates" [value]="eState">{{eState.stateName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isDistrictFilterVisible()">
          <div class="filter-label">
            District
          </div>

          <mat-form-field color="primary" appearance="outline" style="width: 124px;">
            <mat-select color="primary" disableOptionCentering="true" formControlName="selectedCongressionalDistrict">
              <mat-option *ngFor="let district of availableDistricts" [value]="district">{{district}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <div class="filter-label">
            Party
          </div>

          <mat-form-field color="primary" appearance="outline">
            <mat-select color="primary" disableOptionCentering="true" formControlName="selectedParty">
              <mat-option *ngFor="let activeParty of activeParties" [value]="activeParty">{{activeParty.party.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div *ngIf="isEditor">
          <div class="filter-label">
            Still in Race
          </div>

          <mat-form-field color="primary" appearance="outline" style="width: 90px;">
            <mat-select color="primary" disableOptionCentering="true" formControlName="selectedRunningStatus">
              <mat-option *ngFor="let status of runningStatuses" [value]="status">{{status.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isEditor">
          <div class="filter-label">
            Has Picture
          </div>

          <mat-form-field color="primary" appearance="outline" style="width: 90px;">
            <mat-select color="primary" disableOptionCentering="true" formControlName="selectedHasPictureValue">
              <mat-option *ngFor="let hasPic of hasPictureValues" [value]="hasPic">{{hasPic.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="search-container">
          <div class="search-widget">
            <div class="filter-label">
              Free Text
            </div>
            <input class="search-input" placeholder="Candidate Name, State..." formControlName="searchText" />
          </div>
        </div>

        <div class="favorites-toggle" *ngIf="authService.isLoggedIn()">
          <mat-icon *ngIf="!filterByFavorites" (click)="toggleFavorites(true)"
                    matTooltip="Filter for Favorites'" matTooltipPosition="below" matTooltipShowDelay="1000" matTooltipHideDelay="500">favorite_outline</mat-icon>

          <mat-icon *ngIf="filterByFavorites" (click)="toggleFavorites(false)"
                    matTooltip="Remove Filter" matTooltipPosition="below" matTooltipShowDelay="1000" matTooltipHideDelay="500">favorite</mat-icon>

        </div>

        <div class="search-results">

          <div class="filter-label">
            <span *ngIf="filterForm.dirty">Filtered&nbsp;</span>
            <span>Results</span>
          </div>

          <div class="search-results-summary">
            <div>
              <b>{{electionsViewModel?.filteredCandidates?.length | number}}</b> {{electionsViewModel?.filteredCandidates?.length === 1 ? 'Candidate' : 'Candidates'}}
            </div>
            <div>
              <b>{{electionsViewModel?.filteredRaces?.length | number}}</b> {{electionsViewModel?.filteredRaces?.length === 1 ? 'Race' : 'Races'}}
            </div>

          </div>

        </div>

        <button mat-stroked-button (click)="resetFilters()" [disabled]="!filterForm.dirty">Clear Filters</button>

      </div>

      <div class="list-button-container" *ngIf="viewMode == 'list' && false">
        <button [ngClass]="{'isSelected': subviewMode === 'cardView'}" matTooltip="View as Cards" matTooltipShowDelay="200" matTooltipHideDelay="50" (click)="showCards()">
          <mat-icon color="primary">apps</mat-icon>
        </button>
        <button [ngClass]="{'isSelected': subviewMode === 'listView'}" matTooltip="View as List" matTooltipShowDelay="200" matTooltipHideDelay="50" (click)="showList()">
          <mat-icon color="primary">storage</mat-icon>
        </button>
      </div>

      <div class="edit-message" *ngIf="isEditor" style="padding-top: 7px; width: 140px; font-size: 10pt; margin-top: 4px;">
        Double-click a Candidate to Edit them.
      </div>

      <div class="sort-container" *ngIf="viewMode == 'list' && false">

        <div class="filter-label">
          Sorted by
        </div>

        <mat-form-field color="primary" appearance="outline">
          <mat-select color="primary" [(ngModel)]="selectedSortOption">
            <mat-option *ngFor="let sortOption of sortOptions" [value]="sortOption">
              {{sortOption.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div>

    <div class="right-side">
      <!--Put cart icon here-->
    </div>


  </div>

  <div class="main-content">

    <div class="content-panel" *ngIf="electionsViewModel">
      <app-map *ngIf="viewMode === 'map'" #mapViewer ></app-map>
      <app-list *ngIf="viewMode === 'list'" #listViewer></app-list>
    </div>
    
    <app-advisor-panel></app-advisor-panel>
    
  </div>

</div>

