import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabGroup } from '@angular/material';
import { Race } from '../../../types/race';
import { Candidate } from '../../../types/candidate';
import { ElectionsService } from '../../../services/elections.service';
import { CartService } from '../../../services/cart-service';
import { DonationLine } from '../../../types/donation-line';


@Component({
  selector: 'app-candidate-info-dialog',
  templateUrl: './candidate-info-dialog.component.html',
  styleUrls: ['./candidate-info-dialog.component.scss']
})
export class CandidateInfoDialogComponent implements OnInit {

  public candidate: Candidate;
  public opponents: Candidate[];
  private comparisons: any = {};
  public currentOpponentIndex: number;
  public currentOpponent: Candidate;

  public get comparison() {
    if (!this.currentOpponent) {
      return null;
    }

    if (this.comparisons[this.currentOpponent.id] === undefined) {
      this.comparisons[this.currentOpponent.id] = this.getComparison();
    }
    return this.comparisons[this.currentOpponent.id];
  }

  @ViewChild(MatTabGroup, {static: false})
  public opponentTabs: MatTabGroup;

  constructor(
    private electionsService: ElectionsService,
    private cartService: CartService,
    public dialogRef: MatDialogRef<CandidateInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public selectedCandidate: Candidate
  ) {
    this.candidate = selectedCandidate;
  }

  ngOnInit() {
    this.initialize();
  }

  public initialize(): void {
    this.opponents = this.electionsService.getOpponents(this.candidate);
    this.currentOpponentIndex = 0;
    this.currentOpponent = this.opponents[0];

  }

  private getComparison(): any {
    const comp = {
      cashOnHandDelta: Math.abs(this.candidate.endingCash - this.currentOpponent.endingCash),
      isCashOnHandAhead: this.candidate.endingCash - this.currentOpponent.endingCash > 0,

      totalRaisedDelta: Math.abs(this.candidate.totalReceipts - this.currentOpponent.totalReceipts),
      isTotalRaisedAhead: this.candidate.totalReceipts - this.currentOpponent.totalReceipts > 0,

      totalSpentDelta: Math.abs(this.candidate.totalDispursements - this.currentOpponent.totalDispursements),
      isTotalSpentAhead: this.candidate.totalDispursements - this.currentOpponent.totalDispursements > 0
    }

    return comp;
  }

  public opponentChanged(e: number): void {
    this.currentOpponentIndex = e;
    this.currentOpponent = this.opponents[e];
  }

  public donate(amt: number, e: Event) {
    const donationLine = new DonationLine();
    donationLine.candidate = this.candidate;
    donationLine.amount = amt;
    this.cartService.addDonationToCart(donationLine);
  }

  public donateUnspecified(e: Event) {
    const blankDonationLine: DonationLine = new DonationLine();
    blankDonationLine.candidate = this.candidate;
    this.electionsService.requestDonation(blankDonationLine);
  }

  public candidateChanged(opp: Candidate) {
    this.candidate = opp;
    this.initialize();
  }
}
