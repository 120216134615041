<div class="advisor-panel">

  <div class="cart-panel">
    <app-donkey-cart></app-donkey-cart>
  </div>
  <div class="info-panel">
    <app-candidate-info-panel></app-candidate-info-panel>
  </div>

</div>

