import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-donation-success',
  templateUrl: './donation-success.component.html',
  styleUrls: ['./donation-success.component.scss']
})
export class DonationSuccessComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public gotoElections() {
    this.router.navigate(['/elections']);
  }


}
