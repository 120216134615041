import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../../../../services/cart-service';
import { DonationLine } from '../../../../types/donation-line';
import { ElectionsService } from 'src/app/elections/services/elections.service';


@Component({
  selector: 'app-donkey-cart',
  templateUrl: './donkey-cart.component.html',
  styleUrls: ['./donkey-cart.component.scss']
})
export class DonkeyCartComponent implements OnInit {

  public get totalDonation(): number {
    return this.cartService.totalDonation;
  }

  public get donationLines(): DonationLine[] {
    return this.cartService.donationLines;
  }

  constructor(
    private cartService: CartService,
    private electionsService: ElectionsService) { }

  ngOnInit() {
  }

  public editDonation(donationLine: DonationLine) {
    donationLine.isEdit = true;
    this.electionsService.requestDonation(donationLine);
  }

  public removeDonation(donation: DonationLine) {
    this.cartService.removeFromCart(donation);
  }

  public clearDonations(): void {
    this.cartService.clearCart();
  }
}
