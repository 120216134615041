<div class="profile-container">

  <mat-tab-group>
    <mat-tab label="Voter Info">

      <mat-card class="voter-info-panel-card">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>subject</mat-icon>
          </div>
          <mat-card-title>Voter Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <acc-voter-info-panel #voterInfoPanel></acc-voter-info-panel>
        </mat-card-content>

        <mat-card-actions style="text-align: right;">
          <button mat-raised-button color="primary" [disabled]="voterInfoPanel.invalid || voterInfoPanel.pristine || voterInfoPanel.savingInProgress" (click)="voterInfoPanel.saveVoterInfo()">
            <mat-spinner *ngIf="voterInfoPanel.savingInProgress" color="accent" diameter="20" strokeWidth="4" style="height: 200px; width: 24px;"></mat-spinner>
            <mat-icon color="accent" *ngIf="voterInfoPanel.savingComplete" [@inOutAnimation]>done</mat-icon>
            Submit
          </button>
          <button mat-button color="accent" (click)="voterInfoPanel.cancel()">Cancel</button>
        </mat-card-actions>

      </mat-card>

    </mat-tab>
    <mat-tab label="Preferences">
      <acc-preferences></acc-preferences>

    </mat-tab>

    <mat-tab label="Donation History">


    </mat-tab>

    <mat-tab label="Campaigns">

    </mat-tab>

  </mat-tab-group>

</div>


