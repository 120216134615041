import { Party } from './party';
import { Race } from './race';
import { Deserializable } from './deserializable.model';

export class Candidate implements Deserializable {
  public id: number;
  public raceId: number;
  public fecCandidateId: number;
  public name: string;
  public nickname: string;

  public get bestName() {
    if (this.nickname) {
      return this.nickname;
    }
    return this.name;
  }

  public fiveThirtyEightName: string;
  public wikipediaRating: number;
  public paypalAccountEmail: string;
  public partyId: number;
  public party: Party;
  public race: Race;
  public incumbentChallengerStatus: string;
  public beginningCash: number;
  public totalReceipts: number;
  public totalDispursements: number;
  public endingCash: number;
  public headshot: string;
  public isFavorite: boolean;
  public isRunning: boolean;

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
