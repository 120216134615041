<div class="container organize-container">
  <div class=" row">
    <div class="col-12">
      <h1>Get Involved and Organize</h1>

      <h4>Get Involved</h4>
      <p>
        Coming soon: we will let you volunteer for any campaign of your choice. You'll be able to donate your time, work the phone banks, get the vote out, all from the comfort of your home.
      </p>

      <h4>Organize</h4>

      <p>
        The easiest thing you can do is talk to your friends and family is become an advocate for Blunami and how they too can make instantaneous change across the nation by donating to the candidates of their choice in the closest races.
      </p>

      <p>
        Some things you can say:
      </p>

      <ul>
        <li>Blunami lets you donate to candidates anywhere any time</li>
        <li>It tells you which candidates needs your support the most, and where your dollars can make the biggest impact. A candidate who is either certain to win or certain to lose does not need your support. It's the close races that can make the biggest difference</li>
        <li>Better yet, you can pick as many candidates as you want and only check out once. And you will not get spammed by the campaigns you donate to -unless you want to.</li>
      </ul>

    </div>
  </div>
</div>
