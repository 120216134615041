<div class="blu-layout-container">

  <div class="blu-navbar">
    <div class="container-xl">
      <div class="row">

        <div class="col-4 col-lg-5">
          <a [routerLink]="'/'">
            <img src="../assets/images/site/Logo.jpg" />
          </a>
        </div>

        <div class="col-8 col-lg-7 ">

          <!--Main Navs-->
          <ul class="nav">
            <!--<li class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="'elections'" >ELECTIONS</a>
            </li>-->
            <li class="nav-item" routerLinkActive="active"> 
              <a class="nav-link" href="https://vote.org" target="_blank" >VOTE</a>
            </li>

            <!--<li class="nav-item">
              <a class="nav-link" [routerLink]="'about'">ABOUT US</a>
              <mat-icon>expand_more</mat-icon>
            </li>-->

            <li class="nav-item blu-dropdown-item" routerLinkActive="active">

              <a class="blu-dropdown-link">
                <span>ABOUT</span>
                <mat-icon>expand_more</mat-icon>
              </a>

              <ul class="blu-nav-dropdown">
                <li>
                  <a [routerLink]="'/about'" >
                    <span class="mat-subheading-2">About Blunami</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="'/about/how-blunami-works'" routerLinkActive="active">
                    <span class="mat-subheading-2">How it Works</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="'/about/organize'" routerLinkActive="active">
                    <span class="mat-subheading-2">Get Involved</span>
                  </a>
                </li>
                <li class="last-item">
                  <a [routerLink]="'/about/privacy-policy'" routerLinkActive="active">
                    <span class="mat-subheading-2">Privacy Policy</span>
                  </a>
                </li>

              </ul>
            </li>





            <li class="nav-item" *ngIf="!isLoggedIn()"  routerLinkActive="active">
              <a class="nav-link" [routerLink]="'login'">LOG IN</a>
            </li>

            <acc-account-panel *ngIf="isLoggedIn()"></acc-account-panel>

          </ul>


        </div>

      </div>
    </div>
  </div>


  <nav class="blu-mobile-navbar navbar navbar-expand-lg navbar-light bg-light">

    <a class="navbar-brand" [routerLink]="'/'">
      <img src="../assets/images/site/Logo.jpg" class="blu-logo" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">

        <!--<li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="'/elections'">Elections</a>
        </li>-->

        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="https://vote.org" target="_blank">Vote</a>
        </li>

        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            About
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="'/about'">About Blunami</a>
            <a class="dropdown-item" [routerLink]="'/about/how-blunami-works'">How it Works</a>
            <a class="dropdown-item" [routerLink]="'/about/organize'">Get Involved</a>
            <a class="dropdown-item" [routerLink]="'/about/privacy-policy'">Privacy Policy</a>
          </div>
        </li>

        <li class="nav-item" *ngIf="!isLoggedIn()" routerLinkActive="active">
          <a class="nav-link" [routerLink]="'login'">Log In</a>
        </li>

        <li class="nav-item dropdown" *ngIf="isLoggedIn()" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" href="#" id="accountDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span>My Account</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="accountDropdown">
            <a class="dropdown-item" [routerLink]="'/account/profile'">Profile</a>
            <a class="dropdown-item" [routerLink]="'/account/change-password'">Change Password</a>
            <a class="dropdown-item" href="#" (click)="logout()">Log Out</a>
          </div>
        </li>


      </ul>
    </div>
  </nav>


  <div class="blu-body-content blu-custom-scrollbar">
    <router-outlet></router-outlet>
  </div>



</div>





