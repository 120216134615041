import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { LoginComponent } from './login/login.component';
import { NewUserRegistrationComponent } from './new-user-registration/new-user-registration.component';

@Component({
  selector: 'acc-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit {

  @ViewChild('login', {static: true})
  public login: LoginComponent;

  @ViewChild('newUserRegistration', { static: true })
  public newUserRegistration: NewUserRegistrationComponent;

  constructor() { }

  ngOnInit() {
  }

  tabChanged(e: MatTabChangeEvent) {
    if (e.index === 0) {
      this.login.focus();
    }
    else if (e.index === 1) {
      this.newUserRegistration.focus();
    }
  }
 }
