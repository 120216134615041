import { Injectable, Output, EventEmitter  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Race } from '../types/race';
import { Candidate } from '../types/candidate';
import { ElectionsViewModel, ElectionState } from '../types/elections-view-model';
import { DonationLine } from '../types/donation-line';
import { Party, ActiveParty } from '../types/party';
import { CandidateImageRequestModel, CandidateImageResponseModel } from '../types/candidate-image-model';

@Injectable({
  providedIn: 'root'
})
export class ElectionsService {

  public donationRequested: EventEmitter<DonationLine> = new EventEmitter<DonationLine>();
  public showMoreInfoRequested: EventEmitter<Candidate> = new EventEmitter<Candidate>();
  public editCandidateRequested: EventEmitter<Candidate> = new EventEmitter<Candidate>();
  public electionsUpdated: EventEmitter<void> = new EventEmitter<void>();
  public electionsViewModel: ElectionsViewModel;

  constructor(private http: HttpClient) { }

  public getElectionModel(): Observable<ElectionsViewModel> {
    if (this.electionsViewModel) {
      return of(this.electionsViewModel);
    }
    else {
      const url = 'api/elections/all';
      return this.http.get<any>(url).pipe(
        map(result => {
          const model = new ElectionsViewModel();
          model.races = result.races.map(r => new Race().deserialize(r));
          model.candidates = result.candidates.map(c => new Candidate().deserialize(c));
          model.filteredCandidates = [...model.candidates];
          this.populateActiveParties(model);
          this.populateStatesAndDistricts(model);
          this.electionsViewModel = model;

          return model;
        })
      );
    }
  }

  private populateStatesAndDistricts(model: ElectionsViewModel) {
    const eStates: Record<string, ElectionState> = {};

    for (let race of model.races) {
      let eState = eStates[race.stateName];

      if (!eState) {
        eState = new ElectionState();
        eState.stateName = race.stateName;
        eState.stateAbbreviation = race.raceState;
        eStates[race.stateName] = eState;
      }

      if (race.typeCode === 'H') {
        eState.addDistrict(race.district);
      }      
     }

    const allStatesState = new ElectionState();
    allStatesState.stateName = '(All States)';
    allStatesState.stateAbbreviation = '';
    eStates[allStatesState.stateName] = allStatesState;

    model.electionStates = Object.values(eStates).sort((a, b) => {
      if (a.stateName < b.stateName) {
        return -1;
      }
      else if (a.stateName > b.stateName) {
        return 1;
      }
      return 0;
    });

  }

  private populateActiveParties(model: ElectionsViewModel) {

    const representedParties = {};

    for (let candidate of model.candidates) {
      if (!representedParties[candidate.party.id]) {
        const activeParty: ActiveParty = new ActiveParty(candidate.party);
        representedParties[candidate.party.id] = activeParty;
      }
      else {
        const activeParty: ActiveParty = representedParties[candidate.party.id];
        activeParty.occurances += 1;
      }
    }

    let activeParties = Object.values(representedParties) as ActiveParty[];

    // put democrats on top
    const dems = activeParties.find(ap => ap.party.code === "DEM");

    activeParties = activeParties.filter(ap => ap.party.code !== "DEM");

    activeParties = activeParties.sort((a: ActiveParty, b: ActiveParty) => {
      return b.occurances - a.occurances;
    });

    const allParties = new Party();
    allParties.id = -1;
    allParties.code = '';
    allParties.name = "(All Parties)";

    const allActiveParties = new ActiveParty(allParties);
    activeParties = [allActiveParties, dems, ...activeParties];

    model.activeParties = activeParties;
  }

  public isRaceSelected(candidate: Candidate): boolean {
    return this.electionsViewModel.selectedCandidates.some(c => c === candidate);
  }

  public requestDonation(donation: DonationLine): void {
    this.donationRequested.emit(donation);
  }

  public showMoreInfo(candidate: Candidate): void {
    this.showMoreInfoRequested.emit(candidate);
  }

  public editCandidate(candidate: Candidate): void {
    this.editCandidateRequested.emit(candidate);
  }

  public getOpponents(candidate: Candidate) {
    const opponents = this.electionsViewModel.candidates
      .filter(c => c.race.id == candidate.race.id && c.id !== candidate.id);

    return opponents;
  }

  public filter(fvm: any): void {
    this.electionsViewModel.filter(fvm);
    this.electionsUpdated.emit();
  }

  public saveCandidate(candidate: Candidate): Observable<any> {
    const url = 'api/elections/SaveCandidate';
    return this.http.post<boolean>(url, candidate);
  }

  public saveCandidateImage(candidateRequestImageModel: CandidateImageRequestModel): Observable<any> {
    const url = 'api/elections/SaveCandidateImage';
    return this.http.post<CandidateImageResponseModel>(url, candidateRequestImageModel);
  }
}
