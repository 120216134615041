<span *ngIf="!isBusy">
  <mat-icon *ngIf="!candidate.isFavorite" (click)="addToFavorites()"
            [matTooltip]="isLoggedIn ? 'Add to Favorites' : 'Log in to access Favorites'" matTooltipPosition="below" matTooltipShowDelay="1000" matTooltipHideDelay="500" color="primary">favorite_outline</mat-icon>

  <mat-icon *ngIf="candidate.isFavorite" (click)="removeFromFavorites()"
            [matTooltip]="isLoggedIn ? 'Remove from Favorites' : 'Log in to access Favorites'" matTooltipPosition="below" matTooltipShowDelay="1000" matTooltipHideDelay="500" color="primary">favorite</mat-icon>
  
</span>

<mat-spinner *ngIf="isBusy" color="primary" diameter="20" strokeWidth="4" style="height: 200px; width: 24px; margin-top: 0; margin-bottom: 8px;"></mat-spinner>
