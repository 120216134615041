import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ElectionsComponent } from './components/elections/elections.component';
import { MapComponent } from './components/elections/map/map.component';
import { ListComponent } from './components/elections/list/list.component';
import { CandidateCardComponent } from './components/elections/list/candidate-card/candidate-card.component';
import { DonkeyCartComponent } from './components/elections/advisor-panel/donkey-cart/donkey-cart.component';
import { CandidateInfoPanelComponent } from './components/elections/advisor-panel/candidate-info-panel/candidate-info-panel.component';
import { AdvisorPanelComponent } from './components/elections/advisor-panel/advisor-panel.component';
import { DonationDialogComponent } from './components/elections/donation-dialog/donation-dialog.component';
import { CandidateInfoDialogComponent } from './components/elections/candidate-info-dialog/candidate-info-dialog.component';
import { OpponentComponent } from './components/elections/candidate-info-dialog/opponent/opponent.component';
import { CheckoutContainerComponent } from './components/checkout-container/checkout-container.component';
import { CheckoutComponent } from './components/checkout-container/checkout/checkout.component';
import { FavoriteDisplayComponent } from './components/elections/list/favorite-display/favorite-display.component';
import { SharedModule } from '../shared/shared.module';
import { SelectPaymentMethodComponent } from './components/checkout-container/checkout/select-payment-method/select-payment-method.component';
import { EditPaymentMethodComponent } from './components/checkout-container/checkout/select-payment-method/edit-payment-method/edit-payment-method.component';
import { CartEmptyComponent } from './components/checkout-container/cart-empty/cart-empty.component';
import { DonationSuccessComponent } from './components/checkout-container/donation-success/donation-success.component';
import { CandidateEditorDialogComponent } from './components/elections/candidate-editor-dialog/candidate-editor-dialog.component';
import { ReplaceCandidateImageDialogComponent } from './components/elections/candidate-editor-dialog/replace-candidate-image-dialog/replace-candidate-image-dialog.component';


@NgModule({
  declarations: [ElectionsComponent, MapComponent, ListComponent, CandidateCardComponent, DonkeyCartComponent, CandidateInfoPanelComponent, AdvisorPanelComponent, DonationDialogComponent, CandidateInfoDialogComponent, CandidateInfoDialogComponent, OpponentComponent, CheckoutComponent, FavoriteDisplayComponent, SelectPaymentMethodComponent, EditPaymentMethodComponent, CheckoutContainerComponent, CartEmptyComponent, DonationSuccessComponent, CandidateEditorDialogComponent, ReplaceCandidateImageDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  entryComponents: [DonationDialogComponent, CandidateInfoDialogComponent, CandidateEditorDialogComponent, ReplaceCandidateImageDialogComponent]
})
export class ElectionsModule {


}
