import { NewPaymentMethod } from '../../../../types/paymentMethod';
import { DonationLine } from '../../../../types/donation-line';
import { VoterInfo } from '../../../../../shared/types/voterInfo';

export class DonationRequestViewModel {
  public voterInfo: VoterInfo;
  public donationsList: DonationLineDto[];
  public paymentMethodId: number;
  public newPaymentMethod: NewPaymentMethod;
}

export class DonationLineDto {
  public candidateId: number;
  public amount: number;

  constructor(line: DonationLine) {
    this.candidateId = line.candidate.id;
    this.amount = line.amount;
  }
}
