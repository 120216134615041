import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../../services/authentication.service';
import { TokenRequestViewModel } from '../../../types/token-request.view-model';
import { take } from 'rxjs/operators';


@Component({
  selector: 'acc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('emailInput', { static: true })
  public emailInput: ElementRef;

  public loginForm: FormGroup;
  private returnUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/elections';

    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  ngAfterViewInit() {
    this.focus();
  }

  public enterPressed(): void {
    if (this.loginForm.valid) {
      this.login();
    }
  }

  login() {
    const vm: TokenRequestViewModel = new TokenRequestViewModel();
    vm.username = this.loginForm.controls.email.value;
    vm.password = this.loginForm.controls.password.value;

    this.authenticationService.login(vm)
      .pipe(
        take(1)
      )
      .subscribe(result => {
        if (result) {
          this.router.navigateByUrl(this.returnUrl);
        }
      }, err => {
          this.loginForm.setErrors(
            {
              'auth': 'Invalid email or password'
            });
      });
  }

  public focus() {
    setTimeout(() => {
      this.emailInput.nativeElement.focus();
    });
  }
}
