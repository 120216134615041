<div class="container about-container">

  <div class="row">
    <div class="col-12">
        
        <h1 id="what">What is Blunami?</h1>
        <p>It’s a remote activism app that, in this age of pandemics, lets you:</p>
        <ul>
          <li>
            Get involved in politics from the safety of your computer, and soon your phone
          </li>
          <li>
            Help you create your political dream team across the nation – candidates who share your values and have an actual chance of winning
          </li>
          <li>
            Make a real difference, BECAUSE YOU CAN!
          </li>
        </ul>

        <h1 id="how">
          How Blunami Works
        </h1>

        <p>How does Blunami do this?</p>
        <ul>
          <li>
            By providing you with easy, spam-free tools to donate as much or as little of your time and money as you wish to the campaigns of your choice
          </li>
          <li>
            By letting you instantly know whether the candidate you’re leaning towards has a chance of winning. You’re in charge. You choose!
          </li>
          <li>
            In future versions of Blunami you’ll get every candidate’s stance on issues, access to local politics including schoolboards, the ability to interact with like-minded people and app pundits, and a filtered news feed that help you choose your dream team at all levels of politics. You’ll also be able to support causes rather than just candidates, via non-profits or other organizations.
          </li>
        </ul>

        <h1 id="why">
          Why are there Republicans listed here?
        </h1>
        
        <p>
          Because we are not about partisanship. We are about social justice, a sustainable economy and rights and respects for all ethnic, gender and sexual orientation groups, and there are people on all sides who share these values.
        </p>

        <p>
          When Mitt Romney recently called President Trump’s recent firing of multiple Inspectors General a threat to accountable democracy, he earned our respect.
        </p>
        
        <h1 id="money">
          What happens to my money?
        </h1>

        <ul>
          <li>
            Our web site uses state of the art of security and does not store your information locally. If you choose to save your payment information, it is stored on the remote payment gateway system we use: PayPal’s PCI-compliant platform. What lives on the Blunami web site is a token that contains no payment information. If you don’t wish to save your information when using our site, you’ll have to enter it again. The advantage of not saving it is that it is stored nowhere and can’t be stolen.
          </li>
          <li>
            We do not share your information with the campaigns beyond what’s legally required of us: your name, address, employment status and employer. But you can opt out from having that data given to the campaign, or, if you like being harassed for donations you can opt in and let them have it
          </li>
          <li>
            Once you’ve picked all your candidates, you check out ONCE. Brilliant, right?
          </li>
          <li>
            We have come to the unfortunate conclusion that we are not omnipotent. That means we have to rely on payment gateways and payment processors to get your hard-earned money (and ours) to the actual campaigns. This is in the vicinity of 3%. Crazy, right? And then there’s the sliver we need to charge to even be able to offer you this great product, and that’s a measly 1% - but it all adds up. So … If you’re feeling generous you can do two things. You can consider giving the campaigns of your choice 5% more than you originally intended. You can also give Blunami some money so our team can work for actual pay rather than just love. (Don’t get us wrong, love is great but it’s not all you need)
          </li>
          <li id="more">
            Don’t forget there’s more to Blunami than donating money. You can also donate your time. And you can learn about the races by checking the app out on a constant basis and starting conversations with your friends and family about them – and encouraging them to donate time or money. Go ahead and use Blunami to become your own community activist. BECAUSE YOU CAN!
          </li>
        </ul>

        <h1 id="who_are_we">Who are we?</h1>
        <ul>
          <li>
            We are a dedicated team of dreamers, who think the Internet has not delivered on its promise to make democracy and knowledge more accessible, and believe that it can, if as a collective we shift some of our energy from blind consumerism to upholding our common ideals.
          </li>
          <li>
            We are also a practical-minded group, and we fully understand that apps are the conduit through which younger generations (Millenials, Gen Y, Gen Z) will reshape society.
          </li>
          <li>
            Our app is a dashboard for remote activism: the one place where you can support all your causes, find out who supports them and how, and connect with like-minded people.
          </li>
          <li>
            Our team came together in 2020, just as the COVID-19 crisis began to unfold, and it's already clear that in the age of pandemics, democracies will have to accommodate digital debates, interactions and even voting. World-wide.
          </li>
          <li>
            Our app is the first step in this inclusive digital process.
          </li>
          <li>
            Use it and help change the world, BECAUSE YOU CAN!
          </li>
        </ul>

        <h1 id="what_are_we">What are we?</h1>
        <ul>
          <li>
            Because we collect your money, and even though we don’t hold on to it and don’t advocate for anyone in particular even though sometimes we really really want to, we have to operate as a 501©4, which is a political action committee.
          </li>
          <li>
            That means there’s a maximum you can donate to each candidate: $2,800 to be precise, then again, we don’t expect you to give that much, this is just a reminder. What we mean by total is … total: if you’ve already donated $50 to said candidate previously then you’ve only got $2,750 in the kitty.
          </li>
          <li>
            If you are a candidate committee, you can donate $2000 per candidate. If you are a PAC multi-candidate committee, your limit is $5,000. But if you’re that type of organization, you already knew that.
          </li>
          <li>
            It also means we are a non-profit; we don’t return donations for any reason; we are not liable for your opinions, only for ours; It finally means that if you are not an American citizen you can’t contribute to a US-based campaign. But you can contribute to a US-based cause and that will be coming soon…
          </li>
        </ul>


        
        
        
        
    </div>
  </div>

</div>
