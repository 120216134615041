<div class="success-container">
  <mat-card class="success-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>check_circle</mat-icon>
      </div>
      <mat-card-title>Donation Successful</mat-card-title>
    </mat-card-header>

    <mat-card-content style="height: 200px;">
      <p class="mat-body-strong" >
        Your donation has been processed.        
      </p>
      <p class="info">Your donation was successful, and the funds are on the way.  Candidates typically receive donations 2-3 business days after they are made.</p>
      <p class="info">
        You will receive an email verification shortly.  You can also see your donation history from the <a [routerLink]="'/profile'">profile</a> menu.
      </p>
    </mat-card-content>

    <mat-card-actions style="text-align: right;">
      <button mat-raised-button (click)="gotoElections()">Elections</button>
    </mat-card-actions>

  </mat-card>

</div>
