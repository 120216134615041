<div class="candidate-edit-panel" [formGroup]="candidateEditForm">

  <h2 mat-dialog-title>{{candidate.name}}</h2>

  <div mat-dialog-content>

    <div class="columns">

      <div class="left-column">

        <div class="candidate-icon">
          <img *ngIf="candidate?.headshot" src="images/candidates/medium/{{candidate.headshot}}" />
          <mat-icon *ngIf="!candidate?.headshot" color="primary">person_outline</mat-icon>
        </div>

        <button mat-button color="primary" (click)="replaceImage($event)">Replace Image</button>

      </div>

      <div class="column-column">
        <mat-checkbox formControlName="isRunning">{{candidate.name}} is in the Race!</mat-checkbox>

        <mat-form-field>
          <mat-label>Nickname</mat-label>
          <input matInput formControlName="nickname" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>538 Polling Name</mat-label>
          <input matInput formControlName="fiveThirtyEightName" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Wikipedia Rating</mat-label>
          <mat-select formControlName="wikipediaRating">
            <mat-option *ngFor="let wikiRating of wikipediaRatings" [value]="wikiRating.value">{{wikiRating.title}}</mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field>
          <mat-label>Paypal Account Email</mat-label>
          <input matInput formControlName="paypalAccountEmail" />
        </mat-form-field>

      </div>

    </div>


  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="candidate" tabindex="3" [disabled]="candidateEditForm.invalid || candidateEditForm.pristine" (click)="saveCandidate()">Save</button>
    <button mat-button color="accent" (click)="cancel()" tabindex="2">Cancel</button>
  </div>

</div>
