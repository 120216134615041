import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VoterInfo } from '../../types/voterInfo';
import { VoterInfoService } from '../../services/voter-info.service';
  
@Component({
  selector: 'acc-voter-info-panel',
  templateUrl: './voter-info-panel.component.html',
  styleUrls: ['./voter-info-panel.component.scss'],
})
export class VoterInfoPanelComponent implements OnInit {
  public savingInProgress: boolean = false;
  public savingComplete: boolean = false;

  private voterInfo: VoterInfo;
  public voterInfoForm: FormGroup;

  constructor(
    private voterInfoService: VoterInfoService,    
    private router: Router) { }

  ngOnInit() {
    this.voterInfoService.getVoterInfo()
      .subscribe(vi => {
        this.voterInfo = vi;
        this.initializeProfileForm();
      });
  }

  public get invalid(): boolean {
    return this.voterInfoForm && this.voterInfoForm.invalid;
  }

  public get pristine(): boolean {
    return this.voterInfoForm && this.voterInfoForm.pristine;
  }

  private initializeProfileForm() {
    this.voterInfoForm = new FormGroup({
      address1: new FormControl(this.voterInfo ? this.voterInfo.address1 : '', Validators.required),
      address2: new FormControl(this.voterInfo ? this.voterInfo.address2 :''),
      city: new FormControl(this.voterInfo? this.voterInfo.city : '', Validators.required),
      state: new FormControl(this.voterInfo ? this.voterInfo.state : '', Validators.required),
      zip: new FormControl(this.voterInfo ? this.voterInfo.zip : '', Validators.required),
      phone: new FormControl(this.voterInfo ? this.voterInfo.phone : '', Validators.required)
    });
  }

  public saveVoterInfo(): void {
    this.savingInProgress = true;
    const voterInfo: VoterInfo = new VoterInfo(this.voterInfoForm.value);

    this.voterInfoService.saveVoterInfo(voterInfo).subscribe(vi => {
      this.voterInfo = vi;
      this.voterInfoForm.markAsPristine();
      this.savingInProgress = false;
      this.savingComplete = true;

      setTimeout(() => {
        this.savingComplete = false;
      }, 1250);
    });
  }

  public cancel() {
    this.router.navigate(['elections']);
  }
}
