export class Party {
  id: number;
  code: string;
  name: string;
}

export class ActiveParty {
  party: Party;
  occurances: number;

  constructor(party: Party) {
    this.party = party;
    this.occurances = 1;
  }

}
