import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CartService } from '../../../services/cart-service';
import { Router } from '@angular/router';
import { VoterInfoService } from '../../../../shared/services/voter-info.service';
import { VoterInfoPanelComponent } from 'src/app/shared/components/voter-info-panel/voter-info-panel.component';
import { FormGroup } from '@angular/forms';
import { DonationLine } from '../../../types/donation-line';
import { PaymentMethod } from '../../../types/paymentMethod';
import { PaymentMethodService } from '../../../services/payment-method.service';
import { SelectPaymentMethodComponent } from './select-payment-method/select-payment-method.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { DonationService } from './services/donation.service';
import { DonationRequestViewModel, DonationLineDto } from './types/donation-request-view-model';
import { VoterInfo } from '../../../../shared/types/voterInfo';
import { DonationResponseViewModel } from './types/donation-response-view-model';
import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.25s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1.25s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class CheckoutComponent implements OnInit, AfterViewInit {

  @ViewChild("stepper", { static: true })
  public stepper: MatStepper;
  public savingInProgress: boolean = false;

  // #region Voter Info Panel

  @ViewChild("voterInfoPanel", { static: true })
  public voterInfoPanel: VoterInfoPanelComponent;

  public get voterInfoForm(): FormGroup {
    if (this.voterInfoPanel) {
      return this.voterInfoPanel.voterInfoForm;
    }
    return null;
  }

  // #endregion

  // #region Payment Method Panel

  @ViewChild("selectPaymentMethodComponent", { static: true })
  selectPaymentMethodComponent: SelectPaymentMethodComponent;

  // #endregion

  public get donationLines(): DonationLine[] {
    return this.cartService.donationLines;
  }

  public get totalDonation(): number {
    return this.cartService.totalDonation;
  }

  public get cartSummary(): string {
    return this.cartService.getCartSummary();
  }

  public get paymentMethodSummary(): string {
    //const payMethod = this.paymentMethodService.currentPaymentMethod;
    const payMethod = this.selectPaymentMethodComponent.getPaymentMethod();
    return payMethod ? payMethod.name : null;
  }

  public makeDonationACampaign: boolean = false;
  public paymentDeclined: boolean = false;
  public paymentError: boolean = false;

  constructor(
    private cartService: CartService,
    private voterInfoService: VoterInfoService,
    private paymentMethodService: PaymentMethodService,
    private router: Router,
    private donationService: DonationService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  public cartIsEmpty() {
    const isEmpty = this.cartService.cartIsEmpty();
    return isEmpty;
  }

  public gotoElections() {
    this.router.navigate(['/elections']);
  }

  public cancel(): void {
    this.gotoElections();
  }

  public donate() {
    this.savingInProgress = true;
    const request: DonationRequestViewModel = new DonationRequestViewModel();
    const selectedPaymentMethod = this.selectPaymentMethodComponent.getPaymentMethod();

    if (this.voterInfoForm.dirty) {
      request.voterInfo = new VoterInfo(this.voterInfoForm.value);
    }

    request.donationsList = this.cartService.donationLines.map(line => new DonationLineDto(line));

    if (selectedPaymentMethod.accountNumber) {
      request.newPaymentMethod = selectedPaymentMethod;
    }
    else {
      request.paymentMethodId = selectedPaymentMethod.id;
    }

    this.donationService.donate(request)
      .subscribe((result: DonationResponseViewModel) => {
        if (result.succeeded) {
          this.paymentMethodService.clear();
          this.voterInfoService.clear();
          this.cartService.clearCart();
          this.router.navigate(['/checkout/success']);
        }
        else {
          this.paymentDeclined = true;          
        }

        this.savingInProgress = false;

      });
  }

  public goBack(): void {
    this.paymentDeclined = false;
    this.stepper.selectedIndex = 0;
  }
}
