<div [formGroup]="paymentMethodForm" >

  <div class="mat-body-strong" *ngIf="paymentMethods?.length">Select Payment Method:</div>

  <mat-radio-group formControlName="selectedPaymentMethod" *ngIf="paymentMethods?.length > 1">
    <mat-radio-button *ngFor="let pm of paymentMethods" [value]="pm">

      <div class="payment-method" *ngIf="!pm.accountNumber">
        <div class="payment-method-name">
          {{pm.name}}
        </div>
      </div>

    </mat-radio-button>
  </mat-radio-group>

  <app-edit-payment-method #editPaymentMethodComponent [hidden]="!newPaymentMethodSelected"></app-edit-payment-method>

</div>

