import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { style, animate, animation, animateChild, useAnimation, group, sequence, transition, state, trigger, query as q, stagger} from '@angular/animations';
import { Candidate } from 'src/app/elections/types/candidate';
import { ElectionsService } from 'src/app/elections/services/elections.service';
import { CartService } from 'src/app/elections/services/cart-service';
import { ElectionsViewModel } from '../../../types/elections-view-model';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../../../services/authentication.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {

  // data paging properties
  public currentPageOfCandidates: Candidate[];
  private pageSize: number;

  public get numberOfPages(): number {
    return Math.ceil(this.electionsService.electionsViewModel.filteredCandidates.length / this.pageSize);
  }

  public currentPageNumber: number;

  @ViewChild('listPanel', {static: true})
  public listPanel: ElementRef;

  public isLoggedIn: boolean;

  constructor(
    private electionsService: ElectionsService,
    private cartService: CartService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.populateCurrentPageOfCandidates();

    this.electionsService.electionsUpdated.subscribe(() => {
      this.currentPageNumber = 0;
      this.populateCurrentPageOfCandidates();
    });
  }

  ngAfterViewInit() {
    // adjust number of cards displayed depending on page size, to eliminate scrollbars and overflow.  Do it now, and when browser is resized.
    window.onresize = this.resized.bind(this);
    this.resized();
  }

  private populateCurrentPageOfCandidates() {
    setTimeout(() => {
      if (this.currentPageNumber === undefined) {
        this.currentPageNumber = 0;
      }

      const startIndex = this.currentPageNumber * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.currentPageOfCandidates = this.electionsService.electionsViewModel.filteredCandidates.slice(startIndex, endIndex);
    });
  }

  public goFirst() {
    this.currentPageNumber = 0;
    this.populateCurrentPageOfCandidates();
  }

  public goPrevious() {
    this.currentPageNumber--;
    this.populateCurrentPageOfCandidates();
  }

  public goNext() {
    this.currentPageNumber++;
    this.populateCurrentPageOfCandidates();
  }

  public goLast() {
    this.currentPageNumber = this.numberOfPages - 1;
    this.populateCurrentPageOfCandidates();
  }

  public resized(): void {
    this.measureWindow();
  }

  private measureWindow() {
    // determine how many rows/columns of data we can present without overflow
    const height = this.listPanel.nativeElement.offsetHeight;
    const width = this.listPanel.nativeElement.offsetWidth;
    const maxColumns = Math.floor(width / 300);
    const maxRows = Math.floor((height + 19) / 180);  // the 19 accounts for the margin above the paging controls, which have some negative margin applies.
    this.pageSize = maxColumns * maxRows;
    this.populateCurrentPageOfCandidates();
  }

  public trackByCandidateId(index: number, candidate: Candidate) {
    return candidate.id;
  }

  ngOnDestroy() {
  }
}
