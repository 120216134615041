<div class="blu-dialog">

  <div class="blu-dialog-header">

    <div class="candidate-identity">

      <div class="candidate-icon">
        <img *ngIf="candidate?.headshot" src="images/candidates/medium/{{candidate.headshot}}" />
        <mat-icon *ngIf="!candidate?.headshot" color="primary">person_outline</mat-icon>
      </div>

      <div class="identity-info">
        <div class="candidate-name">
          {{candidate?.bestName}}
        </div>

        <span class="candidate-party" [ngClass]="{'democrat': candidate.party.id === 19, 'republican': candidate.party.id === 74}">
          {{candidate?.party.name}}
        </span>
        <span *ngIf="candidate?.incumbentChallengerStatus == 'I'" style="font-style: italic;"> (Incumbent)</span>
        <div class="candidate-office">{{selectedCandidate?.race?.raceType}}</div>
        <div class="race-info">
          <span>{{selectedCandidate?.race.stateName}}</span>
          <span *ngIf="selectedCandidate.race.typeCode === 'H'">, District {{selectedCandidate.race.district}}</span>
        </div>
      </div>

    </div>

    <div class="candidate-donation-stuff">
      <div class="donation-buttons">
        <button mat-stroked-button color="primary" (click)="donate(1, $event)">$1</button>
        <button mat-stroked-button color="primary" (click)="donate(5, $event)">$5</button>
        <button mat-stroked-button color="primary" (click)="donate(10, $event)">$10</button>
        <button mat-stroked-button color="primary" (click)="donateUnspecified($event)">$</button>
      </div>
      <div class="donation-history">
        You have donated $25 to this candidate
      </div>
      <!--<div class="campaign-membership">
        Part of campaign
        <span class="campaign-name">
          <a href="">Swing Revival</a>
        </span>
      </div>-->
      <div class="donation-impact">
        <div class="star-rating">
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
        </div>

      </div>
    </div>

  </div>

  <div class="info-buttons">
    <button mat-raised-button color="primary" [ngClass]="{'active': 'true'}">General</button>
    <button mat-raised-button color="primary">Polling</button>
    <button mat-raised-button color="primary">Funding</button>
  </div>


  <div class="blu-dialog-body">

    <div class="candidate-panel">

      <div class="mat-subheading-2">{{candidate.bestName}} Statistics</div>

      <div class="candidate-content">

        <table class="funding-info">
          <thead>
            <tr>
              <td>
                <h3>Funding</h3>
              </td>
              <td>
                Candidate
              </td>
              <td style="padding-left: 10px; text-align: right;" *ngIf="opponents.length">
                vs. {{currentOpponent?.bestName}}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label">
                Cash on Hand:
              </td>
              <td class="value">
                {{candidate.endingCash | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
              <td class="value" *ngIf="opponents.length">

                <div class="delta-panel">
                  <span class="plusminus">{{ comparison?.isCashOnHandAhead ? ' + ' : ' - ' }}</span>

                  <span class="delta"
                        [ngClass]="{'ahead': comparison?.isCashOnHandAhead, 'behind': !comparison?.isCashOnHandAhead}">
                    {{comparison?.cashOnHandDelta | currency : 'USD' : 'symbol' : '1.0-0'}}
                  </span>
                  
                </div>
                
              </td>
            </tr>
            <tr>
              <td class="label">
                Total Raised:
              </td>
              <td class="value">
                {{candidate.totalReceipts | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
              <td class="value" *ngIf="opponents.length">

                <div class="delta-panel">
                  <span class="plusminus">{{ comparison?.isTotalRaisedAhead ? ' + ' : ' - ' }}</span>

                  <span class="delta"
                        [ngClass]="{'ahead': comparison?.isTotalRaisedAhead, 'behind': !comparison?.isTotalRaisedAhead}">
                    {{comparison?.totalRaisedDelta | currency : 'USD' : 'symbol' : '1.0-0'}}
                  </span>

                </div>

              </td>
            </tr>
            <tr>
              <td class="label">
                Total Spent:
              </td>
              <td class="value">
                {{candidate.totalDispursements | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
              <td class="value" *ngIf="opponents.length">
                <div class="delta-panel">
                  <span class="plusminus">{{ comparison?.isTotalSpentAhead ? ' + ' : ' - ' }}</span>

                  <span class="delta"
                        [ngClass]="{'ahead': comparison?.isTotalSpentAhead, 'behind': !comparison?.isTotalSpentAhead}">
                    {{comparison?.totalSpentDelta | currency : 'USD' : 'symbol' : '1.0-0'}}
                  </span>

                </div>

              </td>
            </tr>

          </tbody>

        </table>

        <h3>Polling</h3>

        <div class="polling-info">
          <div class="label">FiveThirtyEight:</div>
          <div class="value">+ 10%</div>

          <div class="label">Huff Post:</div>
          <div class="value">+ 7%</div>

          <div class="label">Real Clear Politics:</div>
          <div class="value">-19%</div>

        </div>

      </div>


    </div>

    <div class="opponents-panel" *ngIf="opponents.length; else no_opponents_panel">
      <mat-tab-group (selectedIndexChange)="opponentChanged($event)">
        <header></header>
        <mat-tab *ngFor="let opponent of opponents" [label]="opponent?.bestName">
          <app-opponent [opponent]="opponent" [opponentOf]="candidate" (candidateChanged)="candidateChanged($event)"></app-opponent>
        </mat-tab>
      </mat-tab-group>
    </div>
    <ng-template #no_opponents_panel>
      <div class="no-opponents-panel">
        <h1 class="mat-headline">No Opponents</h1>
        <div class="mat-body-2">{{candidate.bestName}} is running unopposed.</div>
      </div>
    </ng-template>
  </div>

</div>
