import { Component, OnInit, Input } from '@angular/core';
import { Candidate } from '../../../../types/candidate';
import { CartService } from 'src/app/elections/services/cart-service';
import { ElectionsService } from 'src/app/elections/services/elections.service';
import { DonationLine } from '../../../../types/donation-line';
import { FavoritesService } from '../../../../services/favorites.service';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../services/authentication.service';

@Component({
  selector: 'app-candidate-card',
  templateUrl: './candidate-card.component.html',
  styleUrls: ['./candidate-card.component.scss']
})
export class CandidateCardComponent implements OnInit {

  @Input()
  public candidate: Candidate;

  @Input()
  public isLoggedIn: Boolean;

  constructor(
    private electionsService: ElectionsService,
    private cartService: CartService,
    private favoritesService: FavoritesService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  public selectCandidate(candidate: Candidate) {  
    this.electionsService.electionsViewModel.selectCandidate(candidate);
  }

  public candidate_dblclick(candidate): void {
    if (!this.authenticationService.isEditor()) {
      return;
    }
    this.electionsService.editCandidate(candidate);
  }

  public isCandidateSelected(candidate: Candidate): boolean {
    return this.electionsService.isRaceSelected(candidate);
  }

  public isCandidateInCart(candidate: Candidate): boolean {
    return this.cartService.isCandidateInCart(candidate);
  }

  public donate(amt: number, e: Event) {
    const donationLine: DonationLine = new DonationLine();
    donationLine.candidate = this.candidate;
    donationLine.amount = amt;
    this.cartService.addDonationToCart(donationLine);
  }

  public donateUnspecified(e: Event) {
    const blankDonation: DonationLine = new DonationLine();
    blankDonation.candidate = this.candidate;
    this.electionsService.requestDonation(blankDonation);
  }


}
