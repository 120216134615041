<div class="container how-blunami-works-container">
  <div class="row">
    <div class="col-12">

      <h1>
        How Blunami Works
      </h1>

      <h4>It all starts with two views: map of races and list of candidates</h4>

      <p>In the US edition of Blunami, you can toggle back and forth any time you want from a map view of all the political races in the country, to a list of all the candidates in all active races.</p>
      <p>In the map view, you can zoom in to a specific race and pick candidates you like, or zoom back out to get a color-coded view of what states are in play, and where your help is needed.</p>
      <p>In the “candidates” view, you can see an info card for every single candidate in every single active race. From the pull-down menus, you can choose what type of candidate you want to see, either by party affiliation or by type of race: Senate, House, Presidential … Blunami will remember your choices. You can clear these filters at any time, by clicking on “Clear Filters”. The Blunami team loves being obvious like that.</p>
      <p>Every info card comes up with an advisor window. The advisor window shows you how much funding the candidate has received to date, how much they’ve spent, how their funding is trending, because let’s face it, the sad truth is that you can’t win in politics without money; and how they are trending in the polls. By clicking on the info button, you can find out who their opponents are, at any time, and see how those folks are doing.</p>
      <p>It’s like watching sports.</p>

      <h4>
        “Ok, but your name is Blunami and yet you list Republican candidates. What’s up?”
      </h4>

      <p>
        Good question, grasshopper. Several reasons. Our mission is to help each and every person make a difference in politics. We believe there are good people on both sides of the aisle. Also, you should absolutely know who your candidates are running against and how these opponents are doing. See above. Otherwise you are missing crucial data in your donation and volunteering strategy.
      </p>

      <h4>
        The true power of Blunami: calculating candidate needs
      </h4>

      <p>
        And this takes us to the true power of Blunami: the reason why it’s about to change politics as we know it. What we are giving you is a needs calculator. Let’s say you are a Democrat voter in California or New York City. You already know that your candidates are going to win because your state is solid blue. But how is that going to impact the rest of the nation? Not in any way. Your candidates don’t need your financial support.
      </p>

      <p>
        Well here’s a way to find candidates who most need your support. You can use Blunami to focus on the tightest races and create your political dream team of deserving candidates.  Of people committed to the issues you care about and the values you stand for. We do most of the work for you by calculating each candidate’s Needs Index.
      </p>

      <p>
        What’s the Needs Index? It’s a score based on the four criteria we’ve mentioned before
      </p>

      <ul>
        <li>Funding to date and money spent</li>
        <li>Trends in funding</li>
        <li>Polling</li>
        <li>Polling Trends</li>
      </ul>

      <p>
        The polling weighs more than the funding in our app. Based on those criteria, we recalculate, every day, a needs score for the candidates you are thinking of supporting, or opposing. The score is expressed as stars, from one: very low need, either because the candidate is certain to win or certain to lose, to five: very high, because the candidate has a chance of winning but needs your support to do so.
      </p>

      <p>
        Amazing, right? Now you can actually spend your money or your time where it’s going to matter most.
      </p>

      <h4>
        Why are candidates who are sure to win rated poorly?
      </h4>

      <p>We focus on the tight races, the ones that are too close to call, the ones whose outcome can change based on your support, the ones where your support has a higher impact. The so-called swing state races, for instance.</p>
      <p>If someone is certain to win (or to lose), is that where your donations will make the biggest difference?</p>
      <p>Probably not. Now we’re back to you having no voice. That said, you can donate to them if you feel like it, that’s up to you. But if your hard-earned dollars matter to you, that’s not an efficient use of resources – we’d like you to focus instead on the candidates who could really benefit from your involvement.</p>

      <h4>
        What’s with the stars, the hearts and the shopping cart?
      </h4>

      <p>Each info card comes with stars, a heart and a donate button.</p>
      <p>The stars, from one to five, tell you how much the candidate deserves your support, and what their chance of winning a close contest are.</p>
      <p>The hearts are a way for you to express your support and to see who else feels that way. Think of the heart as virtual vote.</p>
      <p>The donate button lets you select however many candidates you want and place then into your shopping-cart. Unlike other apps, you can checkout once for any amount of donations.</p>

      <h4>
        What about volunteering?
      </h4>

      <p>
        In a future release, we intend to become the volunteering platform of America. It takes a lot of research and development, but we will get there. Stay tuned.
      </p>

      <h4>
        What about local elections
      </h4>

      <p>
        In a future version of the app, you will be able to zoom all the way in to a local election. Ten dollars says that you don’t know the name of your mayor, are we right? That’s because local elections, from school board, to sheriff, to judges, to municipal, require too much fragmented research. With Blunami, you won’t have to go anywhere – just to your favorite political app to make all of your decisions.
      </p>

      <h4>
        What about other countries?
      </h4>

      <p>
        Someday, the Blunami app will be available in all democracies, and hopefully even in some tyrannies. For now, we are starting with a US-based edition.
      </p>

    </div>

  </div>
</div>
