import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatCheckboxModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatButtonModule, MatMenuModule, MatButtonToggleModule, MatCardModule, MatFormFieldModule,
  MatProgressSpinnerModule, MatTooltipModule, MatInputModule, MatSelectModule, MatDialogModule, MatTabsModule, MatStepperModule, MatRadioModule
} from '@angular/material';
import { VoterInfoPanelComponent } from './components/voter-info-panel/voter-info-panel.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CampaignEditComponent } from './components/campaign-edit/campaign-edit.component';

const materialComponents = [
  MatToolbarModule,
  MatCheckboxModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatButtonToggleModule,
  MatCardModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatButtonToggleModule,
  MatCardModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatInputModule,
  MatSelectModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatStepperModule,
  MatRadioModule
];


@NgModule({
  declarations: [VoterInfoPanelComponent, CampaignEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...materialComponents
  ],
  exports: [
    ...materialComponents,
    VoterInfoPanelComponent,
    CampaignEditComponent
  ]
})
export class SharedModule { }
