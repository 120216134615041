import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Candidate } from '../../../../types/candidate';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ElectionsService } from '../../../../services/elections.service';
import { CandidateImageRequestModel, CandidateImageResponseModel } from '../../../../types/candidate-image-model';

@Component({
  selector: 'app-replace-candidate-image-dialog',
  templateUrl: './replace-candidate-image-dialog.component.html',
  styleUrls: ['./replace-candidate-image-dialog.component.scss']
})
export class ReplaceCandidateImageDialogComponent implements OnInit {
  candidateImageForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ReplaceCandidateImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public candidate: Candidate,
    private electionService: ElectionsService

  ) { }

  ngOnInit() {
    this.candidateImageForm = new FormGroup({
      imageUrl: new FormControl('', [Validators.required, this.validateImageUrl])
    });
  }

  validateImageUrl(imageUrlControl): any {
    if (imageUrlControl.value) {
      const value = imageUrlControl.value;
      const isHttp: boolean = value.startsWith('http');
      const isValidImageType = value.endsWith('.png') || value.endsWith('.gif') || value.endsWith('.jpg') || value.endsWith('.jpeg');
      if (isHttp && isValidImageType) {
        return null;
      }
      return { invalid: 'Image type unsupported' };
    }

  }

  saveImage() {
    const imageRequest = new CandidateImageRequestModel();
    imageRequest.candidateId = this.candidate.id;
    imageRequest.newImageUrl = this.candidateImageForm.controls.imageUrl.value;

    this.electionService.saveCandidateImage(imageRequest).subscribe((response: CandidateImageResponseModel) => {
      this.candidate.headshot = response.newLocalUrl;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

}
