import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { PaymentMethod, NewPaymentMethod } from '../../../../types/paymentMethod';
import { PaymentMethodService } from '../../../../services/payment-method.service';
import { MatDialog } from '@angular/material';
import { EditPaymentMethodComponent } from './edit-payment-method/edit-payment-method.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VoterInfoService } from '../../../../../shared/services/voter-info.service';
import { VoterInfo } from '../../../../../shared/types/voterInfo';
import { merge, forkJoin } from 'rxjs';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit, AfterViewInit {
  private voterInfo: VoterInfo;
  public paymentMethods: PaymentMethod[];

  @ViewChild("editPaymentMethodComponent", { static: true })
  editPaymentMethodComponent: EditPaymentMethodComponent;

  public get paymentMethodForm(): FormGroup {
    return this.paymentMethodService.paymentMethodForm;
  }
  public set paymentMethodForm(value: FormGroup) {
    this.paymentMethodService.paymentMethodForm = value;
  }

  public get isValid(): boolean {
    if (!this.paymentMethods) {
      return false;
    }
    if (this.paymentMethods.length == 1) {
      return this.editPaymentMethodComponent && this.editPaymentMethodComponent.isValid;
    }
    else {
      if (this.newPaymentMethodSelected) {
        return this.editPaymentMethodComponent && this.editPaymentMethodComponent.isValid;
      }
      return this.paymentMethodForm.valid;
    }
  }

  public get newPaymentMethodSelected(): boolean {
    if (this.paymentMethods && this.paymentMethods.length === 1) {
      return true;
    }

    return this.paymentMethodForm.controls.selectedPaymentMethod
      && this.paymentMethodForm.controls.selectedPaymentMethod.value
      && this.paymentMethodForm.controls.selectedPaymentMethod.value.name === '(Add New Payment Method)';
  }

  ngAfterViewInit() {

  }

  constructor(
    private paymentMethodService: PaymentMethodService,
    private voterInfoService: VoterInfoService
  ) { }

  ngOnInit() {
    this.paymentMethodForm = new FormGroup({
      selectedPaymentMethod: new FormControl(null, Validators.required)
    });

    forkJoin(
      this.paymentMethodService.getPaymentMethods(),
      this.voterInfoService.getVoterInfo()
    ).subscribe(result => {
      this.paymentMethods = result[0];
      this.voterInfo = result[1];

      if (this.voterInfo && this.voterInfo.defaultPaymentMethodId) {
        const selectedPaymentMethod = this.paymentMethods.find(pm => pm.id === this.voterInfo.defaultPaymentMethodId);
        this.paymentMethodForm.controls.selectedPaymentMethod.setValue(selectedPaymentMethod);
      }
    });
  }

  public getPaymentMethod(): any {
    if (this.newPaymentMethodSelected) {
      return this.editPaymentMethodComponent.getPaymentMethod();
    }
    else {
      return this.paymentMethodForm.controls.selectedPaymentMethod.value;
    }
    
  }

}
