import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-blunami-works',
  templateUrl: './how-blunami-works.component.html',
  styleUrls: ['./how-blunami-works.component.scss']
})
export class HowBlunamiWorksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
