import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentMethod, PaymentMethodType, NewPaymentMethod } from '../types/paymentMethod';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  private paymentMethodTypes: PaymentMethodType[];
  private paymentMethods: PaymentMethod[];

  public paymentMethodForm: FormGroup;

  public get currentPaymentMethod(): PaymentMethod {
    return this.paymentMethodForm.controls.selectedPaymentMethod.value;
  }

  constructor(private http: HttpClient) { }

  public getPaymentMethodTypes(): Observable<PaymentMethodType[]> {
    if (this.paymentMethodTypes) {
      return of(this.paymentMethodTypes);
    }

    const url = 'api/donations/GetPaymentMethodTypes';

    return this.http.get<PaymentMethodType[]>(url).pipe(
      tap(results => {
        this.paymentMethodTypes = results;
      })
    );
  }

  public getPaymentMethods(): Observable<PaymentMethod[]> {
    if (this.paymentMethods) {
      return of(this.paymentMethods);
    }

    const url = 'api/donations/GetPaymentMethods';

    return this.http.get<PaymentMethod[]>(url).pipe(
      map(results => {
        const addPaymentMethodEntry = new PaymentMethod();
        addPaymentMethodEntry.id = 0;
        addPaymentMethodEntry.name = '(Add New Payment Method)';
        results.push(addPaymentMethodEntry);

        this.paymentMethods = results;
        return results;
      })
    );
  }

  public clear(): void {
    this.paymentMethods = null;
  }

}
