<mat-card class="preferences-card" [formGroup]="preferencesForm" *ngIf="preferencesForm && partyAffinities && partySortOptions">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>subject</mat-icon>
    </div>
    <mat-card-title>Preferences</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <p class="subtext">
      Customize your Blunami experience
    </p>

    <div class="mat-subheading-2">Political Parties</div>

    <p class="mat-body-1">
      You can choose to filter and sort by political party.  You can change this at any time, and you can override these settings using the filter and sort features of the Elections page.
    </p>

    <mat-form-field style="width: 100%;">
      <mat-label>Party Preference:</mat-label>
      <mat-select formControlName="partyAffinityId">

        <mat-select-trigger>
          {{getSelectedPartyAffinityName()}}
        </mat-select-trigger>

        <mat-option *ngFor="let partyAffinity of this.partyAffinities" [value]="partyAffinity.id">
          <div class="affinity-name">{{partyAffinity.name}}</div>
          <div class="affinity-description">{{partyAffinity.description}}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
      <mat-label>Sort by Party:</mat-label>
      <mat-select formControlName="partySortOptionId">

        <mat-select-trigger>
          {{getSelectedPartySortOptionName()}}
        </mat-select-trigger>

        <mat-option *ngFor="let partySortOption of this.partySortOptions" [value]="partySortOption.id">
          <div class="affinity-name">{{partySortOption.name}}</div>
          <div class="affinity-description">{{partySortOption.description}}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>



  </mat-card-content>

  <mat-card-actions style="text-align: right;">
    <button mat-raised-button color="primary" [disabled]="preferencesForm.invalid || preferencesForm.pristine || savingInProgress" (click)="savePreferences()">
      <mat-spinner *ngIf="savingInProgress" color="accent" diameter="20" strokeWidth="4" style="height: 200px; width: 24px;"></mat-spinner>
      <mat-icon color="accent" *ngIf="savingComplete" [@inOutAnimation]>done</mat-icon>
      Submit
    </button>
    <button mat-button color="accent" (click)="cancel()">Cancel</button>
  </mat-card-actions>

</mat-card>
