import { Component, OnInit, Input } from '@angular/core';
import { Preferences } from '../../types/preferences';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LookupsService } from '../../../services/lookups.service';
import { PartyAffinity, PartySortOption } from '../../../types/lookups';
import { PreferencesService } from '../../services/preferences.service';

import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'acc-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.25s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1.25s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class PreferencesComponent implements OnInit {
  public savingInProgress: boolean = false;
  public savingComplete: boolean = false;

  private preferences: Preferences;
  public partyAffinities: PartyAffinity[];
  public partySortOptions: PartySortOption[];

  public preferencesForm: FormGroup;

  constructor(
    private lookupsService: LookupsService,
    private preferencesService: PreferencesService,
    private router: Router) { }

  ngOnInit() {
    this.lookupsService.getPartyAffinities()
      .subscribe(result => {
        this.partyAffinities = result;
      });
    this.lookupsService.getPartySortOptions()
      .subscribe(result => {
        this.partySortOptions = result;
      });

    this.preferencesService.getPreferences().subscribe(prefs => {
      this.preferences = prefs;
      this.initializeForm();
    });
  }

  initializeForm() {
    this.preferencesForm = new FormGroup({
      partyAffinityId: new FormControl(this.preferences ? this.preferences.partyAffinityId : 2, Validators.required),
      partySortOptionId: new FormControl(this.preferences ? this.preferences.partySortOptionId : 1, Validators.required)
    });
  }

  public getSelectedPartyAffinityName(): string {
    const selectedAffinityId = this.preferencesForm.controls.partyAffinityId.value;

    if (selectedAffinityId) {
      return this.partyAffinities.find(pa => pa.id == selectedAffinityId).name;
    }
    return null;
  }

  public getSelectedPartySortOptionName(): string {
    const selectedSortOptionId = this.preferencesForm.controls.partySortOptionId.value;

    if (selectedSortOptionId) {
      return this.partySortOptions.find(pa => pa.id == selectedSortOptionId).name;
    }
    return null;
  }

  public savePreferences() {
    this.savingInProgress = true;

    const preferences = new Preferences(this.preferencesForm.value);

    this.preferencesService.savePreferences(preferences)
      .subscribe(p => {
        this.preferences = p;
        this.preferencesForm.markAsPristine();
        this.savingInProgress = false;
        this.savingComplete = true;
          
        setTimeout(() => {
          this.savingComplete = false;
        }, 1250);
      });
  }

  public cancel() {
    this.router.navigate(['elections']);
  }
}
