<div class="container privacy-policy-container">
  <div class="row">
    <div class="col-12">

      <h1>
        Privacy Policy
      </h1>
      <h6>
        Last updated: 8/1/2020
      </h6>

      <p>
        We operate blunami.org, our web site and app. This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the site and app.
      </p>

      <h4>
        Information Collection And Use
      </h4>
      <p>
        We use your Personal Information in a very limited number of ways:
      </p>

      <div class="info-we-collect">

        <h5>
          Cookies
        </h5>

        <p>
          We use cookies to make your experience more pleasant – but you can opt out of cookies if you so desire. However, if you do not accept cookies, you may not be able to use some portions of our Site.
        </p>

        <h5>
          Log-in
        </h5>

        <p>
          If you create an account with us, this data is kept in a secure database record. This will include your first and last name, your email address and your user nickname. By law, the FEC also requires us to keep a record of your address, employment status and employer if you’re employed.
        </p>
        <p>
          Blunami does not store your credit card information even if you save it on our site. Instead it is stored remote on the Paypal PCI-compliant platform. If you choose not to save your credit card information while using our site, you will have to enter it again if or when you’re kind enough to donate once more. We feel this is a small price to pay to protect yourself from fraud.
        </p>

        <h5>
          Reaching out to you
        </h5>

        <p>
          We may use your Personal Information to contact you with action items: election news, causes you might want to support.  Our goal, in alignment with our company values, is to help you create change rather than serve our revenue needs. We’re narrow-minded like that. Even so, you can opt out of our outreach at any time.
        </p>
        <p>
          By using the Blunami site and app, you agree to the collection and use of information in accordance with the policy you just read.
        </p>

      </div>


      <h4>
        Security
      </h4>

      <p>
        The security of your Personal Information is important to us, but no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we use standard commercial means to protect your Personal Information, we cannot guarantee its absolute security. And that's why we don't store your payment information.
      </p>

      <h4>
        Changes to This Privacy Policy
      </h4>

      <p>
        This Privacy Policy is effective as of 8/1/2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
      </p>

      <p>
        We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
      </p>

      <p>
        If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
      </p>

      <h4>Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy, please contact us (info@blunami.org).

      </p>


    </div>
  </div>
</div>
