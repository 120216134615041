import { Candidate } from './candidate';
import { Deserializable } from './deserializable.model';

export class Race implements Deserializable {
  public id: number;
  public raceYear: number;
  public typeCode: string;
  public raceState: string;
  public stateName: string;
  public district: string;
  public raceType: string;
  public incumbentParty: string;

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
