import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'acc-account-panel',
  templateUrl: './account-panel.component.html',
  styleUrls: ['./account-panel.component.scss']
})
export class AccountPanelComponent implements OnInit {
  public firstName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router)
  { }

  ngOnInit() {
    this.firstName = this.authenticationService.getUserFirstName();
  }

  isRouteActive() {
    return this.router.url.startsWith('/account');
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/elections']);
  }
}
