import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DonationRequestViewModel } from '../types/donation-request-view-model';
import { Observable } from 'rxjs';
import { DonationResponseViewModel } from '../types/donation-response-view-model';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(private http: HttpClient) { }

  public donate(donationRequest: DonationRequestViewModel): Observable<DonationResponseViewModel> {
    const url = 'api/donations/';

    return this.http.post<DonationResponseViewModel>(url, donationRequest);
  }
}
