export class VoterInfo {
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public phone: string;
  public defaultPaymentMethodId: number;

  constructor(formValue: any) {
    this.address1 = formValue.address1;
    this.address2 = formValue.address2;
    this.city = formValue.city;
    this.state = formValue.state;
    this.zip = formValue.zip;
    this.phone = formValue.phone;
  }
}
