<div class="new-payment-method-container" [formGroup]="newPaymentMethodForm" *ngIf="newPaymentMethodForm">

  <h1 mat-dialog-title style="margin-top: 20px;">Enter Payment Method</h1>

  <div mat-dialog-content>
    <mat-form-field class="wide">
      <mat-label>Payment Type</mat-label>

      <mat-select formControlName="paymentMethodType">
        <mat-option *ngFor="let paymentMethodType of paymentMethodTypes" [value]="paymentMethodType">{{paymentMethodType.name}}</mat-option>
      </mat-select>

    </mat-form-field>

    <mat-form-field class="wide">
      <input formControlName="accountNumber" matInput placeholder="Account Number" />
    </mat-form-field>

    <div class="expiration-date">

      <mat-form-field style="margin-right: 10px;">
        <mat-label>Exp Month</mat-label>
        <mat-select formControlName="expirationMonth">
          <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 10px;">
        <mat-label>Exp Year</mat-label>
        <mat-select formControlName="expirationYear">
          <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="last-line">

      <mat-form-field>
        <input formControlName="cvvNumber" matInput placeholder="CVV Number" maxlength="4" />
      </mat-form-field>

      <div class="last-column">

        <mat-checkbox formControlName="savePaymentMethod">Save Payment Method</mat-checkbox>

        <div class="is-this-safe">
          <a href="#">Is this safe?</a>
        </div>

      </div>

    </div>


  </div>

</div>
